import * as Cesium from 'cesium'
import * as h337 from 'heatmap.js'

interface Parmas {
  viewer: Cesium.Viewer,
}

interface Points {
  x: number,
  y: number,
  value: number
}

interface Data {
  width?: number,
  height?: number,
  max: number;
  data: {
      x: number;
      y: number;
      value: number;
  }[];
}

interface Options {
  container?: HTMLElement,
  radius?: number,
  maxOpacity?: number,
  minOpacity?: number,
  blur?: number,
  gradient?: {
    [propName: string]: string
  },
}

class CesiumHeatMap {
  public viewer: Cesium.Viewer
  public entity: any
  public coordinate: number[]
  public data: Data
  public options: Options
  constructor(viewer: Cesium.Viewer, coordinate: number[], data: Data, options: Options) {
    this.viewer = viewer
    this.coordinate = coordinate
    this.data = data
    this.options = options
  }

  static create(viewer: Cesium.Viewer, coordinate: number[], data: Data, options: Options) {
    if (!options.container) {
      // console.log('container')
      let heatDoc = document.createElement("div")
      heatDoc.setAttribute("style", `width: ${data.width || 1000}px; height: ${data.height || 1000}px; margin: 0px; display: none;`)
      document.body.appendChild(heatDoc)
      options.container = heatDoc
    }

    // console.log(data)
    // console.log(options)

    let cesiumHeatMap = new CesiumHeatMap(viewer, coordinate, data, options)
    let heatMap = cesiumHeatMap.createHeatMap(data, options)
    cesiumHeatMap.createRectangle(viewer, coordinate, heatMap)
    viewer.camera.moveEnd.addEventListener(() => cesiumHeatMap.updateHeatMap())
    return cesiumHeatMap
  }

  // 创建热力图
  createHeatMap(data: Data, options: Options) {
    // 创建热力图对象
    // @ts-ignore
    const heatmap = h337.create(options)
    // 添加数据
    // @ts-ignore
    heatmap.setData({
      max: data.max,
      data: data.data,
    })
    return heatmap
  }

  // 创建正方形 绑定热力图 
  createRectangle(viewer: any, coordinate: number[], heatMap: any) {
    this.entity = viewer.entities.add({
      name: 'Cesium Heat map',
      show: true,
      rectangle: {
        coordinates: Cesium.Rectangle.fromDegrees(...coordinate),
        material: heatMap._renderer.canvas // 核心语句，填充热力图
      }
    })
  }

  // 更新热力图
  updateHeatMap() {
    // console.log('cameraHeight')
    const cameraHeight = this.viewer.camera.getMagnitude()
    // if(cameraHeight > 10000000 || cameraHeight < 6375000) return
    this.entity && this.viewer.entities.remove(this.entity)
    const radius = Math.floor(10 + (40 - 10) * (cameraHeight - 6375000) / (10000000 -  6375000))
    // console.log({radius, cameraHeight}) 

    this.data.data = this.data.data.map(item => {
      return {
        ...item,
        radius
      }
    })
    const heatMap = this.createHeatMap(this.data, this.options)
    this.createRectangle(this.viewer, this.coordinate, heatMap)
  }

  // 飞至图层
  flyTo() {
    if(this.entity) {
      this.viewer.flyTo(this.entity);
    }
  }
}

export default CesiumHeatMap