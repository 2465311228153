import { getGridInfoRequest, getMarkerList } from '../api/home'
import actionTypes from './actionTypes'

export const setViewer = (viewer: Cesium.Viewer) => ({
  type: actionTypes.SET_VIEWER,
  viewer
})

export const setLayers = (data: object) => ({
  type: actionTypes.SET_LAYERS,
  data
}) 

export const setTreeData = (data: any[]) => ({
  type: actionTypes.SET_TREE_DATA,
  data
}) 

export const setCheckedKeys = (data: any[]) => ({
  type: actionTypes.SET_CHECKED_KEYS,
  data
}) 

export const setEntities = (data: { key: string, id: string }) => ({
  type: actionTypes.SET_ENTITIES,
  data
})

export const setMarkerEntities = (data: object) => ({
  type: actionTypes.SET_MARKER_ENTITIES,
  data
})

export const setLogoutModal = (data: object) => ({
  type: actionTypes.SET_LOGOUT_MODAL,
  data
})

export const setOriginModal = (data: object) => ({ 
  type: actionTypes.SET_ORIGIN_MODAL,
  data
})

export const setPositionModal = (data: object) => ({
  type: actionTypes.SET_POSITION_MODAL,
  data
})

export const setMeasureVolumeModal = (data: object) => ({
  type: actionTypes.SET_MEASURE_VOLUME_MODAL,
  data
})

const startGetGridInfo = () => ({
  type: actionTypes.START_SET_GRID_INFO,
})

const endGetGridInfo = () => ({
  type: actionTypes.END_SET_GRID_INFO,
})

export const getGridInfo = (params: { area_id: string }, title: string) => {
  return async (dispatch: Function) => {
    dispatch(startGetGridInfo())
    try {
      const res = await getGridInfoRequest(params)
      if (res && res.status === 1) {
        dispatch({
          type: actionTypes.SET_GRID_INFO,
          data: {
            ...res,
            title,
            id: params.area_id
          },
        })
        dispatch(setGridUserModal({
          show: true
        }))
        dispatch(endGetGridInfo())
        return true
      }
    } catch (error) {
      console.log(error)
      dispatch(endGetGridInfo())
      return false
    }

      // getGridInfoRequest(params)
      // .then((res: any) => {
      //   if (res && res.status === 1) {
      //     dispatch({
      //       type: actionTypes.SET_GRID_INFO,
      //       data: {
      //         ...res,
      //         title,
      //       },
      //     })
      //     dispatch(setGridUserModal({
      //       show: true
      //     }))
      //     dispatch(endGetGridInfo())
      //   }
      // }).catch(err => {
      //   console.log(err)
      // })
  }
}

export const setGridUserModal = (data: object) => ({
  type: actionTypes.SET_GRID_USER_MODAL,
  data
})

export const setCalendarModal = (data: object) => ({
  type: actionTypes.SET_CALENDAR_MODAL,
  data
})

export const setUserWarningModal = (data: object) => ({
  type: actionTypes.SET_USER_WARNING_MODAL,
  data
})

export const setGridWarningModal = (data: object) => ({ 
  type: actionTypes.SET_GRID_WARNING_MODAL,
  data
})

export const showLabelModal = (data: object) => ({
  type: actionTypes.SHOW_LABEL_MODAL,
  data
})

export const setMarkerList = (data: object) => ({
  type: actionTypes.SET_MARKER_LIST,
  data
})

export const setMarkerListLoading = (data: boolean) => ({ 
  type: actionTypes.SET_MARKER_LIST_LOADING,
  data
})

export const setMarkerListPage = (data: number) => ({
  type: actionTypes.SET_MARKER_LIST_PAGE,
  data
})

export const setMarkerListPageSize = (data: number) => ({
  type: actionTypes.SET_MARKER_LIST_PAGE_SIZE,
  data
})

export const setMarkerListKeyword = (data: string) => ({
  type: actionTypes.SET_MARKER_LIST_KEYWORD,
  data
})

export const setMarkerListStartTime = (data: string) => ({
  type: actionTypes.SET_MARKER_LIST_START_TIME,
  data
})

export const setMarkerListEndTime = (data: string) => ({
  type: actionTypes.SET_MARKER_LIST_END_TIME,
  data
})

export const setMarkerListParams = (data: any = {}) => {
  return async (dispatch: any, getState: any) => {
    const { page, pageSize, keyword, startAddTime, endAddTime } = data
    const store = getState()
    dispatch(setMarkerListLoading(true))
    try {
      const res = await getMarkerList({
        page: page || store.home.markerListPage,
        limit: pageSize || store.home.markerListPageSize,
        title: keyword || store.home.markerListKeyword,
        startAddTime: startAddTime || store.home.markerListStartAddTime,
        endAddTime: endAddTime || store.home.markerListEndAddTime
      })
      if (res.status === 1) {
        dispatch(setMarkerList(res.data))
      }
      dispatch(setMarkerListLoading(false))
    } catch (err) {
      dispatch(setMarkerListLoading(false))
    }
  }
}

export const setMarkerReport = (data: object) => ({
  type: actionTypes.SET_MARKER_REPORT,
  data 
})

export const setMarkerCurrent = (data: number) => ({
  type: actionTypes.SET_MARKER_CURRENT,
  data 
})

export const setMarkerCheckeds = (data: any[]) => ({
  type: actionTypes.SET_MARKER_CHECKEDS,
  data 
})

export const setUavTrack = (data: object) => ({
  type: actionTypes.SET_UAV_TRACK,
  data
})

export const setVideoList = (data: any[]) => ({
  type: actionTypes.SET_VIDEO_LIST,
  data 
})

export const showStandingBook = (data: object) => ({
  type: actionTypes.SHOW_STANDING_BOOK,
  data
})

export const showStandingBookAdd = (data: any) => ({
  type: actionTypes.SHOW_STANDING_BOOK_ADD,
  data
}) 
