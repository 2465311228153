import React, {
  createRef,
  useState
} from 'react'
import * as Cesium from 'cesium'
import { FormComponentProps } from "antd/lib/form/Form"
import { Icon, Radio } from 'antd'
import styles from './style.module.styl'
import { connect } from 'react-redux'
import { setPositionModal } from '../../actions'
import LongitudeAndLatitude from './components/longitude-and-latitude/LongitudeAndLatitude'
import DegreeOfMinutes from './components/degree-of-minutes/DegreeOfMinutes'


const PositionModal = (props: any) => {
  const { viewer, setPositionModal } = props
  const [currentTab, setCurrentTab] = useState('dec')
  const decFormRef = createRef<FormComponentProps>()
  const degFormRef = createRef<FormComponentProps>()

  const hidePositionModal = () => {
    setPositionModal({
      show: false
    })
  }

  const changeCurrentTab = (e: any) => {
    setCurrentTab(e.target.value)
  }

  const flyTo = (option: { longitude: number, latitude: number, height: number }) => {
    const { longitude, latitude, height = 10 } = option
    viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, height),
      orientation: {
        heading: Cesium.Math.toRadians(0.0),
        pitch: Cesium.Math.toRadians(-25.0),
        roll: 0.0
      }
    })
  }

  const handleDecSubmit = () => {
    // @ts-ignore
    decFormRef.current.form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        flyTo(values)
        hidePositionModal()
      }
    })
  }

  const handleDegSubmit = () => {
    // @ts-ignore
    degFormRef.current.form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        const longitude = values.longitudeDegree + values.longitudeMinute / 60 + values.longitudeSecond / 3600
        const latitude = values.latitudeDegree + values.latitudeMinute / 60 + values.latitudeSecond / 3600
        flyTo({
          longitude,
          latitude,
          height: values.height
        })
        hidePositionModal()
      }
    })
  }

  const tabsPanes: {
    [propName: string]: React.ReactElement
  } = {
    'dec': <LongitudeAndLatitude onSubmit={handleDecSubmit} wrappedComponentRef={decFormRef} />,
    'deg': <DegreeOfMinutes onSubmit={handleDegSubmit} wrappedComponentRef={degFormRef} />,
  }

  return (
    <div className={styles.modal}>
      <header>
        <span>坐标定位</span>
        <Icon type="close" onClick={hidePositionModal} />
      </header>
      <main>
        <div className={styles.modalContent}>
          <Radio.Group
            onChange={changeCurrentTab}
            value={currentTab}
            buttonStyle="solid"
            className={styles.radioGroupWrap}
          >
            <Radio.Button value="dec">十进制</Radio.Button>
            <Radio.Button value="deg">度分秒</Radio.Button>
          </Radio.Group>
          {
            tabsPanes[currentTab]
          }
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer
})

const mapDispatchToProps = {
  setPositionModal
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionModal)