import * as React from 'react'
import { Collapse, Icon } from 'antd'
import * as Cesium from 'cesium'
import { connect } from 'react-redux'
import styles from './style.module.styl'
import { TrackSvg } from '../../../../../components/Icons'
import { getUavData, getUavVideo } from '../../../../../api/home'
import { setUavTrack, setVideoList } from '../../../../actions'

const { useState, useEffect, useCallback } = React
const { Panel } = Collapse

// interface UavCollapseProps {
//   viewer: Cesium.Viewer
//   data: any[]
// }

const UavCollapse = (props: any) => {
  const { viewer, data, uavTrack, setUavTrack, setVideoList, videoList } = props
  const ids = videoList.map((item: any) => {
    return item.id
  })
  console.log(ids)
  const serialNumbers = videoList.map((item: any) => {
    return item.serialNumber
  })

  const callback = (key: any) => {
    console.log(key)
  }

  const flyToUav = (deviceHardId: string) => {
    getUavData({
      deviceHardId
    }).then((res: any) => {
      const { longitude, latitude, altitude } = res.data
      if (!longitude) return
      const position = Cesium.Cartesian3.fromDegrees(parseFloat(longitude), parseFloat(latitude), parseFloat(altitude))

      viewer.camera.flyTo({
        destination: position,
        orientation: {
          heading: Cesium.Math.toRadians(0.0),
          pitch: Cesium.Math.toRadians(-25.0),
          roll: 0.0
        }
      })
    })
  }

  const getHeading = (pointA: Cesium.Cartesian3, pointB: Cesium.Cartesian3): number => {
    //建立以点A为原点，X轴为east,Y轴为north,Z轴朝上的坐标系
    const transform = Cesium.Transforms.eastNorthUpToFixedFrame(pointA);
    //向量AB
    const positionvector = Cesium.Cartesian3.subtract(pointB, pointA, new Cesium.Cartesian3());
    //因transform是将A为原点的eastNorthUp坐标系中的点转换到世界坐标系的矩阵
    //AB为世界坐标中的向量
    //因此将AB向量转换为A原点坐标系中的向量，需乘以transform的逆矩阵。
    const vector = Cesium.Matrix4.multiplyByPointAsVector(Cesium.Matrix4.inverse(transform, new Cesium.Matrix4()), positionvector, new Cesium.Cartesian3());
    //归一化
    const direction = Cesium.Cartesian3.normalize(vector, new Cesium.Cartesian3());
    //heading
    const heading = Math.atan2(direction.y, direction.x) - Cesium.Math.PI_OVER_TWO;
    return Cesium.Math.TWO_PI - Cesium.Math.zeroToTwoPi(heading);
  }

  const getPitch = (pointA: Cesium.Cartesian3, pointB: Cesium.Cartesian3): number => {
    let transfrom = Cesium.Transforms.eastNorthUpToFixedFrame(pointA);
    const vector = Cesium.Cartesian3.subtract(pointB, pointA, new Cesium.Cartesian3());
    let direction = Cesium.Matrix4.multiplyByPointAsVector(Cesium.Matrix4.inverse(transfrom, transfrom), vector, vector);
    Cesium.Cartesian3.normalize(direction, direction);
    //因为direction已归一化，斜边长度等于1，所以余弦函数等于direction.z
    return Cesium.Math.PI_OVER_TWO - Cesium.Math.acosClamped(direction.z);
  }

  const getModelMatrix = (pointA: Cesium.Cartesian3, pointB: Cesium.Cartesian3): Cesium.Matrix4 => {
    //向量AB
    const vector2 = Cesium.Cartesian3.subtract(pointB, pointA, new Cesium.Cartesian3());
    //归一化
    const normal = Cesium.Cartesian3.normalize(vector2, new Cesium.Cartesian3());
    //旋转矩阵 rotationMatrixFromPositionVelocity源码中有，并未出现在cesiumAPI中
    const rotationMatrix3 = Cesium.Transforms.rotationMatrixFromPositionVelocity(pointA, normal, Cesium.Ellipsoid.WGS84);
    const orientation = Cesium.Quaternion.fromRotationMatrix(rotationMatrix3);
    const modelMatrix4 = Cesium.Matrix4.fromRotationTranslation(rotationMatrix3, pointA);
    //点B的模型矩阵
    //const modelMatrix4 = Cesium.Matrix4.fromRotationTranslation(rotationMatrix3, pointB);
    const hpr = Cesium.HeadingPitchRoll.fromQuaternion(orientation);
    return modelMatrix4;
  }

  const showUavTrack = (item: any) => {
    console.log(item)
    const { deviceHardId } = item

    if (uavTrack[deviceHardId] && uavTrack[deviceHardId].show) {
      uavTrack[deviceHardId].show = false
      clearInterval(uavTrack[deviceHardId].timer)
      viewer.entities.remove(uavTrack[deviceHardId].entity)
      setUavTrack(uavTrack)
    } else {
      let position: any = Cesium.Cartesian3.fromDegrees(
        -123.0744619,
        44.0503706,
        5000.0
      ), positions: any = []

      let heading, pitching, rolling, orientation, hpr

      let entity = viewer.entities.add({
        position: new Cesium.CallbackProperty((time, result) => {
          return position
        }, false),
        polyline: {
          // This callback updates positions each frame.
          positions: new Cesium.CallbackProperty((time, result) => {
            return positions
          }, false),
          width: 6,
          material: new Cesium.PolylineOutlineMaterialProperty({
            color: Cesium.Color.RED,
            outlineWidth: 2,
            outlineColor: Cesium.Color.WHITE,
          }),
        },
        //Automatically compute orientation based on position movement.
        model: {
          uri: './model/1800_lowpoly_4gltf.glb',
          // uri: '/model/Cesium_Air.glb',
          minimumPixelSize: 64,
          maximumScale: 20000,
          scale: 0.2,
        }
      })

      const timer = setInterval(() => {
        getUavData({
          deviceHardId: deviceHardId
        }).then((res: any) => {
          // console.log(res)
          const { longitude, latitude, altitude, yaw, pitch, roll } = res.data
          if (!longitude) return
          position = Cesium.Cartesian3.fromDegrees(parseFloat(longitude), parseFloat(latitude), parseFloat(altitude))
          positions.push(Cesium.Cartesian3.fromDegrees(parseFloat(longitude), parseFloat(latitude), parseFloat(altitude)))

          heading = Cesium.Math.toRadians(parseFloat(yaw) + 90)
          pitching = Cesium.Math.toRadians(parseFloat(pitch))
          rolling = Cesium.Math.toRadians(parseFloat(roll))
          hpr = new Cesium.HeadingPitchRoll(heading, pitching, rolling)
          orientation = Cesium.Transforms.headingPitchRollQuaternion(
            position,
            hpr
          )

          if (entity) {
            entity.orientation = orientation
          }
        })
      }, 1000)

      flyToUav(item.deviceHardId)

      setUavTrack({
        [deviceHardId]: {
          show: true,
          timer,
          entity
        }
      })
    }
  }

  const showUavVideo = (item: any) => {
    const { deviceHardId } = item
    getUavVideo({
      videoType: 'hls',
      deviceHardId
    }).then((res: any) => {
      if (res.status === 1) {
        let length = videoList.length
        let newVideoList: any = [].concat(videoList)
        let index = ids.indexOf(deviceHardId)
        let serialNumber = 1

        if (index > -1) {
          newVideoList.splice(index, 1)
        } else {
          if (length >= 4) {
            serialNumber = newVideoList[0].serialNumber
            console.log(serialNumber)
            newVideoList.splice(0, 1)
          } else {
            for (let i = 1; i <= 4; i++) {
              if (serialNumbers.indexOf(i) === -1) {
                serialNumber = i
                break
              }
            }
          }
          newVideoList.push({
            serialNumber,
            id: deviceHardId,
            videoType: 'hls',
            videoName: res.data.deviceName,
            videoUrl: res.data.vUrl
          })
        }
        console.log(newVideoList)
        setVideoList(newVideoList)
      }
    })
  }

  return (
    <div>
      {
        data.map((item: any) => <Collapse onChange={callback} className={styles.collapse} key={item.device_id}>
          <Panel className={styles.panel} header={
            <div className={styles.panelHeader}>
              <span title={item.deviceName}>{item.deviceName}</span>
              <Icon type="play-circle" title="视频" className={styles.videoIcon}
                style={{ color: ids.some((id: any) => id === item.deviceHardId) ? '#1890ff' : '#fff' }}
                onClick={(e) => {
                  e.stopPropagation()
                  showUavVideo(item)
                }
                } />
              <Icon component={TrackSvg} title="轨迹" className={styles.teackIcon}
                style={{ color: uavTrack[item.deviceHardId] && uavTrack[item.deviceHardId].show ? '#1890ff' : '#fff' }}
                onClick={(e) => {
                  e.stopPropagation()
                  showUavTrack(item)
                }
                } />
            </div>
          } key={item.device_id}>
            <ul className={styles.ul}>
              <li>
                <span>型号：</span>
                <span>{item.deviceSerial}</span>
              </li>
              <li>
                <span>类型：</span>
                <span>{item.goods_name}</span>
              </li>
              <li>
                <span>连接：</span>
                <span>{item.httpStatus === 1 ? '连接' : '未连接'}</span>
              </li>
              <li>
                <span>挂载：</span>
                <span>{item.httpStatus === 1 ? '在线' : '离线'}</span>
              </li>
              <li>
                <span>视频流：</span>
                <span>{item.httpStatus === 1 ? '在线' : '离线'}</span>
              </li>
            </ul>
          </Panel>
        </Collapse>)
      }
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
  uavTrack: state.home.uavTrack,
  videoList: state.home.videoList
})

const mapDispatchToProps = {
  setUavTrack,
  setVideoList
}

export default connect(mapStateToProps, mapDispatchToProps)(UavCollapse) 