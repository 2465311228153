import React from 'react'
import { connect } from 'react-redux'
import { Popover } from 'antd'
import { 
  createGoogleMapsByUrl, 
  creatTiandituImgProvider,
  creatArcGisImgMapProvide,
  creatArcGisBlueMapProvide,
  creatTiandituImgLabelProvider, 
} from '../../../utils/LoadGeoJson'
import styles from './style.module.styl'
import img_0 from '../../../static/images/tianditu.png'
import img_1 from '../../../static/images/arcgisBase.png'
import img_2 from '../../../static/images/arcgisBlue.png'
import img_3 from '../../../static/images/arcgisGrey.png'

const { useState, useCallback } = React

const baseMapList = [
  {
    id: 0,
    title: 'Google底图',
    src: img_3
  },
  {
    id: 1,
    title: '天地图',
    src: img_0
  },
  {
    id: 2,
    title: 'ArcGIS底图',
    src: img_1
  },
  {
    id: 3,
    title: 'ArcGIS蓝底',
    src: img_2
  }
]

const BaseMap = (props: any) => {
  const { viewer } = props
  const [visible, setVisible] = useState(false)
  const [current, setCurrent] = useState(0)

  const setBaseMap = useCallback(
    (key: number) => {
      viewer.imageryLayers.removeAll()
      switch (key) {
        case 0:
          viewer.imageryLayers.addImageryProvider(createGoogleMapsByUrl())
          viewer.imageryLayers.addImageryProvider(creatTiandituImgLabelProvider())
          break
        case 1:
          viewer.imageryLayers.addImageryProvider(creatTiandituImgProvider())
          viewer.imageryLayers.addImageryProvider(creatTiandituImgLabelProvider())
          break
        case 2:
          viewer.imageryLayers.addImageryProvider(creatArcGisImgMapProvide())
          viewer.imageryLayers.addImageryProvider(creatTiandituImgLabelProvider())
          break
        case 3:
          viewer.imageryLayers.addImageryProvider(creatArcGisBlueMapProvide())
          viewer.imageryLayers.addImageryProvider(creatTiandituImgLabelProvider())
          break
        default:
          break
      }
      setVisible(false)
    }, [viewer]
  )

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible)
  }

  const onChangeBaseMap = (id: number) => {
    if (id !== current) {
      setCurrent(id)
      setBaseMap(id)
    }
  }

  const BaseMapList = (
    <div className={styles.baseMapWrap}>
      <ul className={styles.baseMapList}>
        {
          baseMapList.map(item => (
            <li key={item.id} onClick={() => onChangeBaseMap(item.id)}
              className={item.id === current ? [styles.baseMapItem, styles.baseMapItemActive].join(' ') : styles.baseMapItem}
            >
              <img src={item.src} alt="底图切换" />
              <span>{item.title}</span>
            </li>
          ))
        }
      </ul>
    </div>
  )

  return (
    <div className={styles.baseMap}>
      <Popover
        content={BaseMapList}
        trigger="click"
        visible={visible}
        placement="topRight"
        onVisibleChange={handleVisibleChange}
      >
        <div className={styles.baseMapBtn}>
          <img src={img_3} alt="底图切换" />
          <span>底图切换</span>
        </div>
      </Popover>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(BaseMap)
