import request from '../utils/Request'

export function login(data: object) {
  return request({
    url: '/api/login/usercenter',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/api/login/logout',
    method: 'get'
  })
}

export function loginRequest(data: object) {
  return request({
    url: '/api/login/webgis-login',
    method: 'post',
    data
  })
}

export function logoutRequest() {
  return request({
    url: '/api/login/logout',
    method: 'get'
  })
}