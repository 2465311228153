import React, { useRef } from 'react'
import { Icon } from 'antd'
import { connect } from 'react-redux'
import styles from './style.module.styl'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { setVideoList } from '../../actions'

const { useEffect } = React

const UavVideo = (props: any) => {
  const { data, videoList, setVideoList } = props
  const videoRef = useRef(null)
  const { id, videoName, videoUrl } = data
  const ids = videoList.map((item: any) => {
    return item.id
  })

  const closeUavVideoModal = () => {
    let newVideoList: any = [].concat(videoList)
    let index = ids.indexOf(id)
    newVideoList.splice(index, 1)
    console.log(newVideoList)
    setVideoList(newVideoList)  
  }

  useEffect(() => {
    console.log(videoUrl)
    const player = videojs(videoRef.current, {
      controls: true,
      controlBar: {
        // @ts-ignore
        pictureInPictureToggle: false  // 画中画
      },
    })
    player.reset()
    player.src({
      type: 'application/x-mpegURL',
      src: videoUrl
    })
    player.ready(function () {
      player.play()
    })
  }, [videoUrl])

  return (
    <div className={styles.modal}>
      <header>
        <span>{videoName}</span>
        <Icon type="close" onClick={closeUavVideoModal} />
      </header>
      <main>
        <div ref={videoRef} className="video-js vjs-big-play-centered"></div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  videoList: state.home.videoList
})

const mapDispatchToProps = {
  setVideoList
}

export default connect(mapStateToProps, mapDispatchToProps)(UavVideo)