import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Icon, InputNumber } from 'antd'
import * as Cesium from 'cesium'
import styles from './style.module.styl'
import drawGraphic from '../../../utils/DrawGraphic'
import { getArea } from '../../../utils/CesiumTool'
import { setMeasureVolumeModal } from '../../actions'

export const MeasureVolumeModal = (props: any) => {
  const { viewer, setMeasureVolumeModal } = props
  const [baseHeight, setBaseHeight] = useState(0)
  const [minWallHeight, setMinWallHeight] = useState(0)
  const [maxWallHeight, setMaxWallHeight] = useState(0)
  const [backShapePoints, setBackShapePoints] = useState([])
  const [labelEntity, setLabelEntity] = useState<any>()
  const [baseEntity, setBaseEntity] = useState<any>()
  const [excavateVolume, setExcavateVolume] = useState('')
  const [buryVolumes, setBuryVolumes] = useState('')
  const [triangle, setTriangle] = useState([])
  const [lonlats, setLonlats] = useState<any>()
  const [wallEntities, setWallEntities] = useState<any>()
  const [draw, setDraw] = useState(false)

  const drawVolume = () => {
    setDraw(true)
    if (wallEntities) {
      wallEntities.forEach((entity: Cesium.Entity) => {
        viewer.entities.remove(entity)
      })
      setWallEntities(null)
    }
    // 绘制方法 point | line | polygon
    drawGraphic(viewer, 'polygon', (backShapePoints: any, backShapeEntities: any) => {
      setDraw(false)
      setBackShapePoints(backShapePoints)
      // 删除临时实体
      backShapeEntities.forEach((entity: Cesium.Entity) => {
        viewer.entities.remove(entity)
      })

      VolumeAnalysis(backShapePoints)
    })
  }

  //计算体积
  const VolumeAnalysis = (points: any) => {

    viewer.scene.globe.depthTestAgainstTerrain = true // 开启地形深度检测
    let lonlats = [], minHeight = 10000
    for (let i = 0; i < points.length; i++) {
      // @ts-ignore
      let cartographic = Cesium.Cartographic.fromCartesian(points[i])
      // @ts-ignore
      let height = viewer.scene.sampleHeight(cartographic)

      // @ts-ignore
      if (minHeight > height) {
        // @ts-ignore
        minHeight = height
      }
      //经纬度值
      let lng = Cesium.Math.toDegrees(cartographic.longitude),
        lat = Cesium.Math.toDegrees(cartographic.latitude)
      lonlats.push(lng, lat, 0)
    }

    setLonlats(lonlats)

    //设置网格粒度
    let granularity = Math.PI / Math.pow(2, 15)
    granularity = granularity / (64)
    //创建多边形平面几何体
    // @ts-ignore
    let polygonGeometry = new Cesium.PolygonGeometry.fromPositions(
      {
        positions: points,
        vertexFormat: Cesium.PerInstanceColorAppearance.FLAT_VERTEX_FORMAT,
        granularity: granularity
      }
    )

    //创建多边形平面几何体
    // @ts-ignore
    let geom = new Cesium.PolygonGeometry.createGeometry(polygonGeometry)
    let totalVolume = 0
    let maxHeight = 0
    let i0, i1, i2
    let height1, height2, height3
    let p1, p2, p3
    let cartesian
    // @ts-ignore
    let cartographic
    let bottomArea
    let buryVolumes = 0
    let excavateVolume = 0
    let cartesian1, cartesian2, cartesian3
    let triangle: any = []

    //循环计算网格的节点, indices顶点索引数据，用于确定几何中的基元
    for (let i = 0; i < geom.indices.length; i += 3) {
      i0 = geom.indices[i]
      i1 = geom.indices[i + 1]
      i2 = geom.indices[i + 2]
      //获取几何体三角面第一个面点
      cartesian1 = new Cesium.Cartesian3(geom.attributes.position.values[i0 * 3],
        geom.attributes.position.values[i0 * 3 + 1],
        geom.attributes.position.values[i0 * 3 + 2])
      //获取几何体三角面第二个面点
      cartesian2 = new Cesium.Cartesian3(geom.attributes.position.values[i1 * 3],
        geom.attributes.position.values[i1 * 3 + 1],
        geom.attributes.position.values[i1 * 3 + 2])
      //获取几何体三角面第三个面点
      cartesian3 = new Cesium.Cartesian3(geom.attributes.position.values[i2 * 3],
        geom.attributes.position.values[i2 * 3 + 1],
        geom.attributes.position.values[i2 * 3 + 2])
      // @ts-ignore
      const polyCenter = Cesium.BoundingSphere.fromPoints([cartesian1, cartesian2, cartesian3]).center
      // @ts-ignore
      cartographic = Cesium.Cartographic.fromCartesian(polyCenter)

      const height = viewer.scene.sampleHeight(cartographic)

      // @ts-ignore
      if (maxHeight < height) {
        // @ts-ignore
        maxHeight = height
      }

      //计算三角面的面积
      // @ts-ignore
      bottomArea = getArea([cartesian1, cartesian2, cartesian3])

      triangle.push({
        height,
        bottomArea
      })

      if (height > minHeight) {
        // @ts-ignore
        excavateVolume = excavateVolume + bottomArea * (height - minHeight)
      }

      if (height < minHeight) {
        // @ts-ignore
        buryVolumes = buryVolumes + bottomArea * (minHeight - height)
      }

    }

    //创建体积墙
    const text = `挖方体积：${excavateVolume.toFixed(1)}立方米\n填方体积：${buryVolumes.toFixed(1)}立方米`
    createWall(lonlats, minHeight, maxHeight, points, text)

    setTriangle(triangle)
    setBaseHeight(Number(minHeight.toFixed(2)))
    setMinWallHeight(Number(minHeight.toFixed(2)))
    setMaxWallHeight(Number(maxHeight.toFixed(2)))
    setExcavateVolume(`${excavateVolume.toFixed(1)}立方米`)
    setBuryVolumes(`${buryVolumes.toFixed(1)}立方米`)

  }

  const createWall = (lonlats: any, minHeight: any, maxHeight: any, points: any, totalVolume: any) => {

    const maxLonlats = lonlats.map((item: any) => {
      if (item === 0) {
        return maxHeight
      } else {
        return item
      }
    })


    let centroid = Cesium.BoundingSphere.fromPoints(Cesium.Cartesian3.fromDegreesArrayHeights(maxLonlats)).center
    let labelEntity = new Cesium.Entity({
      // @ts-ignore
      position: centroid,
      label: {
        // @ts-ignore
        text: totalVolume,
        font: '14pt Microsoft YaHei',
        // @ts-ignore
        showBackground: true,
        // @ts-ignore
        backgroundColor: Cesium.Color.BLACK.withAlpha(0.5),
        // @ts-ignore
        perPositionHeight: true,
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(1.0, 50000.0),
        // @ts-ignore
        disableDepthTestDistance: 5000.0
      }
    })
    viewer.entities.add(labelEntity)
    setLabelEntity(labelEntity)

    // @ts-ignore
    let entity = new Cesium.Entity({
      // @ts-ignore
      polygon: {
        // @ts-ignore
        hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(maxLonlats),
        material: Cesium.Color.CYAN.withAlpha(0.4),
        extrudedHeight: 0,
        // @ts-ignore
        perPositionHeight: true,
        closeTop: false,
        closeBottom: false,
      },
    })
    viewer.entities.add(entity)

    const minLonlats = lonlats.map((item: any) => {
      if (item === 0) {
        return minHeight
      } else {
        return item
      }
    })

    let baseEntity = new Cesium.Entity({
      // @ts-ignore
      polygon: {
        // @ts-ignore
        hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(minLonlats),
        material: Cesium.Color.GREEN.withAlpha(0.4),
        // @ts-ignore
        clampToGround: false,
        // @ts-ignore
        perPositionHeight: true
      },
    })
    viewer.entities.add(baseEntity)
    setBaseEntity(baseEntity)

    setWallEntities([labelEntity, entity, baseEntity])
  }

  //计算体积
  const againVolume = (minHeight: number) => {

    let buryVolumes = 0
    let excavateVolume = 0
    triangle.forEach(item => {
      const { height, bottomArea } = item
      if (height > minHeight) {
        // @ts-ignore
        excavateVolume = excavateVolume + bottomArea * (height - minHeight)
      }

      if (height < minHeight) {
        // @ts-ignore
        buryVolumes = buryVolumes + bottomArea * (minHeight - height)
      }
    })

    labelEntity.label.text = `挖方体积：${excavateVolume.toFixed(1)}立方米\n填方体积：${buryVolumes.toFixed(1)}立方米`
    const tempLonlats = lonlats.map((item: any) => {
      if (item === 0) {
        return minHeight
      } else {
        return item
      }
    })
    baseEntity.polygon.hierarchy = Cesium.Cartesian3.fromDegreesArrayHeights(tempLonlats)
    setExcavateVolume(`${excavateVolume.toFixed(1)}立方米`)
    setBuryVolumes(`${buryVolumes.toFixed(1)}立方米`)
  }

  const onChange = (value: any) => {
    setBaseHeight(value)
    againVolume(value)
  }

  const closeModal = () => {
    if (wallEntities) {
      wallEntities.forEach((entity: Cesium.Entity) => {
        viewer.entities.remove(entity)
      })
      setWallEntities(null)
    }
    setMeasureVolumeModal({
      show: false
    })
  }

  useEffect(() => {
    return () => {
      if (wallEntities) {
        wallEntities.forEach((entity: Cesium.Entity) => {
          viewer.entities.remove(entity)
        })
        setWallEntities(null)
      }
    }
  }, [wallEntities])

  return (
    <section className={styles.section}>
      <header className={styles.header}>
        <span>体积测量</span>
        <Icon type="close" className={styles.close} onClick={closeModal} />
      </header>
      <main className={styles.main}>
        <div className={styles.drwaBtnWrap}>
          <Button
            type="primary"
            title="左键点击地图开始绘制，右键结束绘制"
            onClick={drawVolume}
            disabled={draw}
          >
            {draw ? '点击地图开始绘制' : '绘制测量区域'}
          </Button>
        </div>
        <div className={styles.inputWrap}>
          <label>基准面高：</label>
          <InputNumber value={baseHeight} onChange={onChange} />
        </div>
        <div className={styles.hint}>
          <label>围栏底高：</label>
          <span>{minWallHeight}</span>
        </div>
        <div className={styles.hint}>
          <label>围栏顶高：</label>
          <span>{maxWallHeight}</span>
        </div>
        <div className={styles.hint}>
          <label>挖方体积：</label>
          <span>{excavateVolume}</span>
        </div>
        <div className={styles.hint}>
          <label>填方体积：</label>
          <span>{buryVolumes}</span>
        </div>
      </main>
    </section>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
})

const mapDispatchToProps = {
  setMeasureVolumeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasureVolumeModal)
