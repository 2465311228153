import point_icon_01 from '../static/icons/point_icon_01.svg'
import point_icon_02 from '../static/icons/point_icon_02.svg'
import point_icon_03 from '../static/icons/point_icon_03.svg'
import point_icon_04 from '../static/icons/point_icon_04.svg'
import point_icon_05 from '../static/icons/point_icon_05.svg'
import point_icon_06 from '../static/icons/point_icon_06.svg'
import point_icon_07 from '../static/icons/point_icon_07.svg'
import point_icon_08 from '../static/icons/point_icon_08.svg'
import point_icon_09 from '../static/icons/point_icon_09.svg'
import point_icon_10 from '../static/icons/point_icon_10.svg'
import point_icon_11 from '../static/icons/point_icon_11.svg'
import point_icon_12 from '../static/icons/point_icon_12.svg'


export default [
  {
    key: '1',
    label: '默认',
    image: point_icon_01
  },
  {
    key: '2',
    label: '排污',
    image: point_icon_02
  },
  {
    key: '3',
    label: '违建',
    image: point_icon_03
  },
  {
    key: '4',
    label: '垃圾',
    image: point_icon_04
  },
  {
    key: '5',
    label: '彩钢棚',
    image: point_icon_05
  },
  {
    key: '6',
    label: '水面垃圾',
    image: point_icon_06
  },
  {
    key: '7',
    label: '水闸',
    image: point_icon_07
  },
  {
    key: '8',
    label: '烟窗',
    image: point_icon_08
  },
  {
    key: '9',
    label: '秸秆焚烧点',
    image: point_icon_09
  },
  {
    key: '10',
    label: '政府',
    image: point_icon_10
  },
  {
    key: '11',
    label: '学校',
    image: point_icon_11
  },
  {
    key: '12',
    label: '医院',
    image: point_icon_12
  },
]