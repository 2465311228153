import * as React from 'react'
import { Collapse, Icon } from 'antd'
import * as Cesium from 'cesium'
import { getUavList } from '../../../api/home'
import { connect } from 'react-redux'
import styles from './style.module.styl'
import UavCollapse from './components/uav-collapse/UavCollapse'
import { TrackSvg, LabelDelSvg, LabelAccountSvg } from '../../../components/Icons'

const { useState, useEffect, useCallback } = React
const { Panel } = Collapse

const UavManage = (props: any) => {
  const { viewer } = props
  const [onlineList, setOnlineList] = useState<any[]>([])
  const [offlineList, setOfflineList] = useState<any[]>([])

  const callback = (key: any) => {
    console.log(key)
  }

  const showUavTrack = (data: any) => {
    console.log(data)
  }

  useEffect(() => {
    getUavList({}).then((res: any) => {
      console.log(res)
      if(res.status === 1) {
        let onlineList: any[] = [], offlineList: any[] = []
        res.data.forEach((item: any) => {
          item.list.forEach((subItem: any) => {
            subItem.httpStatus === 1 ?
              onlineList.push(subItem) :
              offlineList.push(subItem)
          })
        })
        setOnlineList(onlineList)
        setOfflineList(offlineList)
      }
    })
  }, [])

  return (
    <section className={styles.section}>
      <header className={styles.header}>
        <span>标注管理</span>
      </header>
      <main className={styles.main}>
        <Collapse defaultActiveKey={['1']} onChange={callback} className={styles.collapse}>
          <Panel header="在线" key="1" className={styles.panel}>
            <UavCollapse data={onlineList} />
            {/* {
              onlineList.map(item => <Collapse onChange={callback} className={styles.collapse} key={item.device_id}>
                <Panel header={
                  <div className={styles.panelHeader}>
                    <span title={item.deviceName}>{item.deviceName}</span>
                    <Icon type="play-circle" title="视频" className={styles.videoIcon} onClick={(e) => {
                      e.stopPropagation()
                      showUavTrack(item)} 
                    } />
                    <Icon component={TrackSvg} title="轨迹" className={styles.teackIcon} onClick={(e) => {
                      e.stopPropagation()
                      showUavTrack(item)} 
                    } />
                  </div>
                } key={item.device_id}>
                  <ul className={styles.ul}>
                    <li>
                      <span>型号：</span>
                      <span>{item.deviceSerial}</span>
                    </li>
                    <li>
                      <span>类型：</span>
                      <span>{item.goods_name}</span>
                    </li>
                    <li>
                      <span>连接：</span>
                      <span>{item.httpStatus === 1 ? '连接' : '未连接'}</span>
                    </li>
                    <li>
                      <span>挂载：</span>
                      <span>{item.httpStatus === 1 ? '在线' : '离线'}</span>
                    </li>
                    <li>
                      <span>视频流：</span>
                      <span>{item.httpStatus === 1 ? '在线' : '离线'}</span>
                    </li>
                  </ul>
                </Panel>
              </Collapse>)
            } */}
          </Panel>
          <Panel header="离线" key="2" className={styles.panel}>
            <UavCollapse data={offlineList} />
          </Panel>
        </Collapse>
      </main>
    </section>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(UavManage) 