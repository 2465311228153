import React, {
  forwardRef,
  useImperativeHandle
} from 'react'
import Form, { FormComponentProps } from "antd/lib/form/Form"
import { Button, Row, Col, InputNumber } from 'antd'
import styles from './style.module.styl'

interface FormProps extends FormComponentProps {
  onSubmit: () => void
}

type Ref = FormComponentProps

const DegreeOfMinutes = forwardRef<Ref, FormProps>(
  ({ form, onSubmit }: FormProps, ref) => {
    useImperativeHandle(ref, () => ({
      form
    }))

    const { getFieldDecorator } = form

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      onSubmit()
    }

    return (
      <Form onSubmit={handleSubmit} className={styles.paneTwo}>
        <Row>
          <Col span={5}>
            <Form.Item label="经度" labelCol={{ span: 22 }} wrapperCol={{ span: 0 }}></Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="">
              {getFieldDecorator('longitudeDegree', {
                rules: [
                  {
                    required: true,
                    message: '-180至180',
                  },
                ]
              })(
                <InputNumber max={180} min={-180} />
              )}
              <span className="ant-form-text"> 度</span>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="">
              {getFieldDecorator('longitudeMinute', {
                rules: [
                  {
                    required: true,
                    message: '-60至60',
                  },
                ]
              })(
                <InputNumber max={60} min={-60} />
              )}
              <span className="ant-form-text"> 分</span>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="">
              {getFieldDecorator('longitudeSecond', {
                rules: [
                  {
                    required: true,
                    message: '-60至60',
                  },
                ]
              })(
                <InputNumber max={60} min={-60} />
              )}
              <span className="ant-form-text"> 秒</span>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Form.Item label="纬度" labelCol={{ span: 22 }} wrapperCol={{ span: 0 }}></Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="">
              {getFieldDecorator('latitudeDegree', {
                rules: [
                  {
                    required: true,
                    message: '-90至90',
                  },
                ]
              })(
                <InputNumber max={90} min={-90} />
              )}
              <span className="ant-form-text"> 度</span>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="">
              {getFieldDecorator('latitudeMinute', {
                rules: [
                  {
                    required: true,
                    message: '-60至60',
                  },
                ]
              })(
                <InputNumber max={60} min={-60} />
              )}
              <span className="ant-form-text"> 分</span>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="">
              {getFieldDecorator('latitudeSecond', {
                rules: [
                  {
                    required: true,
                    message: '-60至60',
                  },
                ]
              })(
                <InputNumber max={60} min={-60} />
              )}
              <span className="ant-form-text"> 秒</span>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Form.Item label="海拔" labelCol={{ span: 22 }} wrapperCol={{ span: 0 }}></Form.Item>
          </Col>
          <Col span={19}>
            <Form.Item label="">
              {getFieldDecorator('height', {})(
                <InputNumber min={0} className={styles.inputNumberHeight} />
              )}
              <span className="ant-form-text"> 米</span>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit" block>飞入</Button>
        </Form.Item>
      </Form>
    )
  }
)

export default Form.create<FormProps>()(DegreeOfMinutes)
