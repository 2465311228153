import * as React from 'react'
import { Tree } from 'antd'
import { getServiceMap } from '../../../api/home'
import { loadLayer, showLayer, flyToLayer } from '../../../utils/LoadGeoJson'
import { connect } from 'react-redux'
import { setLayers, setCheckedKeys } from '../../actions'

const { useState, useEffect, useCallback } = React
const { TreeNode } = Tree

const BaseLayer = (props: any) => {
  const { viewer, layers, setLayers, treeData, checkedKeys, setCheckedKeys } = props
  const [expandedKeys, setExpandedKeys] = useState([])
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  // const [checkedKeys, setCheckedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  // const [treeData, setTreeData] = useState([])

  // useEffect(() => {
  //   let isActual = true
  //   getServiceMap().then(res => {
  //     if (!isActual) return
  //     if (res.status === 1) {
  //       setTreeData(res.data)
  //       const checked = res.data.map((item: { id: string }) => item.id)
  //       // setCheckedKeys(checked)
  //     }
  //   })
  //   return () => { isActual = false }
  // }, [setCheckedKeys])

  const loadLayers = (data: Object[]) => {
    data.forEach((item: any) => {
      console.log('item', item)
      if (item.url && !layers[item.id]) {
        console.log(item.url)
        let layer = loadLayer(viewer, item)
        // console.log(layer) 
        setLayers({ 
          [item.id]: layer
        })
      }
      console.log('serviceData', item.serviceData)
      if (item.serviceData) {
        console.log(item.serviceData)
        loadLayers(item.serviceData)
        // item.serviceData.forEach((subItem: any) => {
        //   if (subItem.url && !layers[subItem.id]) {
        //     console.log(subItem.url)
        //     let layer = loadLayer(viewer, subItem)
        //     // console.log(layer) 
        //     setLayers({ 
        //       [subItem.id]: layer
        //     })
        //   }
        // })
      }
    })
  }

  useEffect(() => {
    // loadLayers(treeData)
    // treeData.forEach((item: any) => {
    //   if (item.url && !layers[item.id]) {
    //     console.log(item.url)
    //     let layer = loadLayer(viewer, item)
    //     // console.log(layer) 
    //     setLayers({ 
    //       [item.id]: layer
    //     })
    //   }
    //   if (item.serviceData) {
    //     // loadLayers(item.serviceData)
    //   }
    // })
  })

  const onExpand = (expandedKeys: any) => {
    // console.log('onExpand', expandedKeys)
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeys)
    setAutoExpandParent(false)
  }

  const setLayerShow = (item: any, show: boolean) => {
    if (layers[item]) {
      if (!!layers[item] && typeof layers[item].then === 'function') {
        layers[item].then((dataSource: any) => {
          dataSource.show = show
        })
      } else {
        layers[item].show = show
      }
    }
  }

  const onCheck = (checkedKeys: any, e?: any) => {
    console.log(checkedKeys, e)
    showLayer(checkedKeys, e.checked)
    if (e.checked) {
      checkedKeys.forEach((item: string) => {
        console.log(item)
        setLayerShow(item, true)
      })
    } else {
      const noCheckedKeys = Object.keys(layers).filter(item => {
        return checkedKeys.indexOf(item) === -1
      })
    
      noCheckedKeys.forEach((item: string) => {
        setLayerShow(item, false)
        console.log(item)
      })
    }
    setCheckedKeys(checkedKeys)
  }

  const onSelect = (selectedKeys: any, e: any) => {
    // flyToLayer(viewer, e.node.props.eventKey)
    console.log(layers)
    console.log(e.node.props.eventKey)
    layers[e.node.props.eventKey] && viewer.flyTo(layers[e.node.props.eventKey])
    setSelectedKeys(selectedKeys)
  }

  const renderTreeNodes = (data: any): any => {
    return data.map((item: any) => {
      if (item.serviceData) {
        return (
          <TreeNode title={item.name} key={item.id} dataRef={item}>
            {renderTreeNodes(item.serviceData)}
          </TreeNode>
        )
      }
      return <TreeNode key={item.id} title={item.name} />
    })
  }

  return (
    <div>
      <header>
        <span>基础图层</span>
      </header>
      <main>
        <Tree
          checkable
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
        >
          {renderTreeNodes(treeData)}
        </Tree>
      </main>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
  layers: state.home.layers,
  treeData: state.home.treeData,
  checkedKeys: state.home.checkedKeys,
})

const mapDispatchToProps = {
  setLayers,
  setCheckedKeys
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayer) 