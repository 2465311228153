import React, { useEffect, useState } from 'react'
import { Icon, Table, Button, Divider, Popconfirm, message } from 'antd'
import { connect } from 'react-redux'
import styles from './style.module.styl'
import { showStandingBook, showStandingBookAdd } from '../../actions'
import { getStandingBook, deleteStandingBook } from '../../../api/home'

export const MarkerStandingBook = (props: any) => {
  const { standingBook, showStandingBook, showStandingBookAdd } = props
  const [tableData, setTableData] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center' as any
    },
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
      align: 'center' as any
    },
    {
      title: '图片数量',
      dataIndex: 'img_num',
      key: 'img_num',
      align: 'center' as any
    },
    {
      title: '视频地址',
      dataIndex: 'video',
      key: 'video',
      align: 'center' as any
    },
    {
      title: '问题日期',
      dataIndex: 'problem_time',
      key: 'problem_time',
      align: 'center' as any
    },
    {
      title: '报告可见',
      dataIndex: 'is_show',
      key: 'is_show',
      align: 'center' as any,
      render: (text: any, record: any) => <span>{record.is_show === '1' ? '显示' : '隐藏'}</span>
    },
    {
      title: '操作',
      key: 'action',
      align: 'center' as any,
      render: (text: any, record: any) => (
        <span>
          <Button className={styles.actionBtn} type="link" onClick={() => handleEdit(record)}>编辑</Button>
          <Divider type="vertical" />
          <Popconfirm
            title="确定删除?"
            onConfirm={() => handleDelete(record)}
            okText="是"
            cancelText="否"
          >
            <Button className={styles.actionBtn} type="link">删除</Button>
          </Popconfirm>
        </span>
      )
    },
  ]

  const handleAdd = () => {
    console.log('add')
    showStandingBook({
      show: false
    })
    showStandingBookAdd({
      show: true,
      ...standingBook
    })
  }

  const handleEdit = (record: any) => {
    console.log(record)
    showStandingBook({
      show: false
    })
    showStandingBookAdd({
      show: true,
      record: record,
      ...standingBook
    })
  }

  const handleDelete = (record: any) => {
    console.log(record)
    deleteStandingBook({
      id: record.id
    }).then(res => {
      console.log(res)
      if(res.status === 1) {
        message.success('删除成功')
        fetchTableData({
          marker_id: standingBook.marker_id,
          limit: 10,
          page: 1
        })
      } else {
        message.error('删除失败') 
      }
    }).catch((err) => {
      message.error('删除失败') 
    })
  }

  const closeModal = () => {
    showStandingBook({
      show: false
    })
  }

  const fetchTableData = (params: any) => {
    setLoading(true)
    getStandingBook(params).then((res: any) => {
      console.log(res)
      if (res.status === 1) {
        setLoading(false)
        setTableData(res.data.list)
        setTotal(parseInt(res.data.total))
      }
    })
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { current, pageSize } = pagination
    fetchTableData({
      marker_id: standingBook.marker_id,
      page: current,
      limit: pageSize,
    })
  }

  useEffect(() => {
    fetchTableData({
      marker_id: standingBook.marker_id,
      limit: 10,
      page: 1
    })
  }, [standingBook.marker_id])

  return (
    <div className={styles.modal}>
      <header>
        <span>{standingBook.title}-台账</span>
        <Icon type="close" onClick={closeModal} />
      </header>
      <main>
        <div className={styles.cont}>
          <Button onClick={handleAdd} type="primary" className={styles.btn}>
            新增台账
          </Button>
          <Table
            columns={columns}
            dataSource={tableData}
            rowKey={(record: any) => record.id}
            size="middle"
            bordered
            loading={loading}
            onChange={handleTableChange}
            pagination={{
              pageSize: 10,
              total,
              showQuickJumper: false
            }}
            rowClassName={(record, index) => {
              let className = styles.lightRow
              if (index % 2 === 1) className = styles.darkRow
              return className
            }}
          />
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  standingBook: state.home.standingBook
})

const mapDispatchToProps = {
  showStandingBook,
  showStandingBookAdd
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkerStandingBook)
