import { createStore, combineReducers, applyMiddleware, compose } from 'redux'

import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import { reducer as weatherReducer } from './manage'
import { reducer as loginReducer } from './login'
import { reducer as homeReducer } from './home'

const reducer = combineReducers({
  weather: weatherReducer,
  login: loginReducer,
  home: homeReducer
})

// const middlewares = [thunkMiddleware, logger]
const middlewares = [thunkMiddleware]

const storeEnhancers = compose(
  composeWithDevTools(
    applyMiddleware(...middlewares)
  )
)

export default createStore(reducer, {}, storeEnhancers)