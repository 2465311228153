import React, { useRef, useState, useEffect } from 'react'
import { Icon, Button } from 'antd'
import { connect } from 'react-redux'
import styles from './style.module.styl'
import { setMarkerReport } from '../../actions'
import moment from 'moment'
import { Chart } from '@antv/g2'
import { getMakerTags, exportMarkerReport } from '../../../api/home'

export const MarkerReport = (props: any) => {
  const { markerReport, setMarkerReport } = props
  const [tagNum, setTagNum] = useState(0)
  const [total, setTotal] = useState(0)
  const [chart, setChart] = useState<any>()

  const closeModal = () => {
    setMarkerReport({
      show: false
    })
  }

  const handleReport = () => {
    const { checkeds } = markerReport

    exportMarkerReport({
      marker_id: checkeds.join(',')
    }).then((res: any) => {
      if (!res) {
        return
      }
      let url = window.URL.createObjectURL(new Blob([res]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', moment().format('YYYY-MM-DD') + '巡检报告.doc')
      document.body.appendChild(link)
      link.click()
    })
  }

  useEffect(() => {
    const { checkeds } = markerReport
    getMakerTags({
      marker_ids: checkeds.join(',')
    }).then(res => {
      console.log(res)
      if (res.status === 1) {
        setTagNum(checkeds.length)
        setTotal(res.data.total)

        if (chart) {
          chart.changeData(res.data.list)
        }
      }
    })
  }, [chart, markerReport])

  useEffect(() => {
    const data: any = [{
      name: "",
      num: 0
    }]

    const chart = new Chart({
      container: 'container',
      autoFit: true,
      height: 500,
    })

    chart.data(data)
    chart.scale('num', {
      nice: true,
    })

    chart.tooltip({
      showMarkers: false
    })
    chart.interaction('active-region')

    chart.interval().position('name*num')

    chart.render()

    setChart(chart)
  }, [])


  return (
    <div className={styles.modal}>
      <header>
        <span>巡检报告</span>
        <Icon type="close" onClick={closeModal} />
      </header>
      <main>
        <div className={styles.cont}>
          <div className={styles.contHead}>
            <Button className={styles.exportBtn} type="primary" ghost onClick={handleReport}>
              <Icon className={styles.exportIcon} type="download" />导出报告
            </Button>
          </div>
          <p>本次巡检报告发布日期为{moment().format('YYYY年MM月DD日')}，共有标注点{tagNum}个，疑似缺陷{total}处，缺陷情况如下图所示：</p>
          <div id="container" className={styles.container}></div>
          <p>缺陷详情见导出报告正文。</p>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  markerReport: state.home.markerReport
})

const mapDispatchToProps = {
  setMarkerReport
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkerReport)
