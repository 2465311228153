import React from 'react'
import { connect } from 'react-redux'
import styles from './style.module.styl'
import GridManage from '../grid-manage/GridManage'

const { useState } = React

const OneMapTabs = (props: any) => {
  const [currentTab, setCurrentTab] = useState('0-0')

  const tabsData = [
    {
      id: '0-0',
      title: '网格管理',
    },
  ]

  const tabsPanes: {
    [propName: string]: React.ReactElement
  } = {
    '0-0': <GridManage />,
  }

  return (
    <div className={styles.siderSection}>
      <div className={styles.siderLeft}>
        <div className={styles.tabs}>
          {
            tabsData.map(item => <div onClick={() => setCurrentTab(item.id)} className={item.id === currentTab ? styles.active : null} key={item.id}>{item.title}</div>)
          }
          {/* <div className={styles.active}>基础图层</div>
              <div>标注管理</div>
              <div>动画导航</div>
              <div>无人机</div> */}
        </div>
      </div>
      <div className={styles.siderRight}>
        {/* <BaseLayer /> */}
        {
          tabsPanes[currentTab]
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const homeData = state.home

  return {
    home: homeData,
    viewer: homeData.viewer,
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(OneMapTabs)