import React from 'react'
import { Icon, Button } from 'antd'
import styles from './style.module.styl'
import { connect } from 'react-redux'
import { setOriginModal } from '../../actions'
import { updateOrigin } from '../../../api/home'

const OriginModal = (props: any) => {

  const setOrigin = async () => {
    const params = {
      longitude: props.viewer.camera.position.x,
      latitude: props.viewer.camera.position.y,
      height: props.viewer.camera.position.z,
      heading: props.viewer.camera.heading,
      pitch: props.viewer.camera.pitch,
      roll: props.viewer.camera.roll,
    }
    updateOrigin({
      point: params
    })
    props.setOriginModal({
      show: false
    })
  }

  const hideOriginModal = () => {
    props.setOriginModal({
      show: false
    })
  }

  return (
    <div className={styles.modal}>
      <header>
        <span>设置原点</span>
        <Icon type="close" onClick={hideOriginModal} />
      </header>
      <main>
        <div className={styles.modalContent}>
          <p>是否将此视点坐标设置为原点位置？</p>
          <div className={styles.modalBtn}>
            <Button size="small" type="primary" onClick={setOrigin}>是</Button>
            <Button size="small" onClick={hideOriginModal}>否</Button> 
          </div>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const viewer = state.home.viewer

  return {
    viewer: viewer,
  }
}

const mapDispatchToProps = {
  setOriginModal
}

export default connect(mapStateToProps, mapDispatchToProps)(OriginModal)  