import * as React from 'react'
import { connect } from 'react-redux'
// import * as Status from './status'

// const Weather = ({ status, cityName, weather, lowestTemp, highestTemp }) => {
//   return <div>天气信息请求中...</div>
// }

class View extends React.Component<any> {
  public render() {
    return (
      <div>Manage</div>
    )
  }
}

const mapStateToProps = (state: any) => {
  const weatherData = state.weather

  return {
    status: weatherData.status,
  }
}

export default connect(mapStateToProps)(View)