import React from 'react'
import { Icon, Collapse, Spin, Input, Button } from 'antd'
import moment from 'moment'
import styles from './style.module.styl'
import { connect } from 'react-redux'
import { setGridUserModal, setCalendarModal, setUserWarningModal } from '../../actions'
import { exportExcel } from '../../../api/home'

const { Panel } = Collapse
const { Search } = Input
const { useState, useEffect } = React

interface UserList {
  title: string,
  data: any[]
}

const genExtra = (props: any, handleClickEye: Function, isSelected: boolean) => (
  <Icon
    type="eye"
    style={{ fontSize: '17px', color: isSelected ? '#3ea8ee' : '#a0a3af' }}
    onClick={event => {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation()
      handleClickEye({
        show: true,
        ...props
      })
    }}
  />
)

const userListInit: UserList[] = [
  {
    title: '在线人员',
    data: []
  },
  {
    title: '离线人员',
    data: []
  }
]

const GridUserModal = (props: any) => {
  const { gridInfo, calendarModal, setCalendarModal, setUserWarningModal } = props
  const [userList, setUserList] = useState(userListInit)
  const [initUserList, setInitUserList] = useState(userListInit)
  const [selectedUser, setSelectedUser] = useState()
  const [reportAddressUser, setReportAddressUser] = useState()

  useEffect(() => {
    const { gridInfo } = props
    let onLineUser: any[] = [], offLineUser: any[] = [], reportAddressUser: number = 0
    gridInfo.data.forEach((item: any) => {
      if (item.status === '1') {
        onLineUser.push(item)
      } else {
        offLineUser.push(item)
      }
      if (item.home_report === 1) {
        reportAddressUser++
      }
    })
    let userList = [
      {
        title: '在线人员',
        data: [...onLineUser]
      },
      {
        title: '离线人员',
        data: [...offLineUser]
      }
    ]
    setUserList(userList)
    setInitUserList(userList)
    setReportAddressUser(reportAddressUser)
  }, [props])

  useEffect(() => {
    if (!calendarModal.show) {
      setSelectedUser(null)
    }
  }, [calendarModal])

  const hideModal = () => {
    props.setGridUserModal({
      show: false
    })
  }

  const callback = (key: any) => {
    console.log(key)
  }

  const handleClickEye = (params: { show: boolean, phone: string, id: string, name: string }) => {
    const { show, phone, id, name } = params
    console.log({ show, phone, id })
    setCalendarModal({
      show,
      phone,
      name
    })
    setSelectedUser(id)
  }

  const handelSearch = (val: string) => {
    let userList: any = []
    for (var i = 0; i < initUserList.length; i++) {
      // 如果字符串中不包含目标字符会返回-1
      userList[i] = {}
      userList[i].title = initUserList[i].title
      userList[i].data = []
      for (let j = 0; j < initUserList[i].data.length; j++) {
        if (initUserList[i].data[j].name.indexOf(val) >= 0) {
          userList[i].data.push(initUserList[i].data[j]);
        }
      }
    }
    setUserList(userList)
  }

  const handelExportReport = () => {
    exportExcel({
      id: gridInfo.id
    }).then((res: any) => {
      if (!res) return
      let url = window.URL.createObjectURL(new Blob([res]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', gridInfo.title + moment().format('YYYY年MM月DD日HH时mm分') + '.xls')
      document.body.appendChild(link)
      link.click()
    })
  }

  const handelUserWarnimg = () => {
    setUserWarningModal({
      show: true,
      name: gridInfo.name,
      id: gridInfo.id
    })
  }

  return (
    <div className={styles.wrap}>
      <Spin spinning={gridInfo.isLoading}>
        <div className={styles.modal}>
          <header>
            <span>{gridInfo.title}</span>
            <Icon type="close" onClick={hideModal} />
          </header>
          <div className={styles.searchBar}>
            <Search onSearch={handelSearch} placeholder="搜索" />
          </div>
          <div className={styles.wraningBar}>
            <span className={styles.text}>已上报住址{reportAddressUser}人</span>
            <Button className={styles.reportLink} type="link" icon="download" ghost onClick={handelExportReport} >导出</Button>
            <Button className={styles.warningLink} type="link" icon="exclamation-circle" ghost onClick={handelUserWarnimg} >警告</Button>
          </div>
          <main>
            <div className={styles.modalContent}>
              <Collapse onChange={callback}>
                {
                  userList.map((item: any, index) => (
                    <Panel header={`${item.title} （${item.data.length}）人`} key={index}>
                      {
                        <Collapse onChange={callback}>
                          {
                            item.data.map((subItem: any) => (
                              <Panel header={subItem.name} key={subItem.id} extra={genExtra(subItem, handleClickEye, selectedUser === subItem.id)}>
                                <div className={styles.panelContent}>
                                  <p>电话：{subItem.phone}</p>
                                  <p>住址：{subItem.home_report === 1 ? '已上报' : '未上报'}</p>
                                </div>
                              </Panel>
                            ))
                          }
                        </Collapse>
                      }
                    </Panel>
                  ))
                }
              </Collapse>
            </div>
          </main>
        </div>
      </Spin>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
  gridInfo: state.home.gridInfo,
  calendarModal: state.home.calendarModal
})

const mapDispatchToProps = {
  setGridUserModal,
  setCalendarModal,
  setUserWarningModal
}

export default connect(mapStateToProps, mapDispatchToProps)(GridUserModal)  