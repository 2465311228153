import React, { useState, useEffect, useCallback } from 'react'
import * as Cesium from 'cesium'
import { connect } from 'react-redux'
import { Icon } from 'antd'
import { SetOriginSvg, FlyOriginSvg, SetDecSvg, HorMeasureSvg, VerMeasureSvg, AreaMeasureSvg, volumeMeasureSvg } from '../../../components/Icons'
import styles from './style.module.styl'
import { setOriginModal, setPositionModal, setMeasureVolumeModal } from '../../actions'
import { getOrigin } from '../../../api/home'
import { measure, DrawingMode } from '../../../utils/CesiumTool'

const DRAW_TYPE = {
  horizontal: 'horizontal',
  vertical: 'vertical',
  area: 'area',
  volume: 'volume'
}

const ToolBar = (props: any) => {
  const { viewer, originModal, setOriginModal, positionModal, setPositionModal, setMeasureVolumeModal } = props
  const [current, setCurrent] = useState('')
  const [handler, setHandler] = useState<any>()
  const [entities, setEntities] = useState<any[]>([])

  const showOriginModal = () => {
    setOriginModal({  
      show: true
    })
  }

  const flyToOrigin = useCallback(() => {
    if (viewer.camera) {
      getOrigin().then((res: any) => {
        if (res.status === 1) {
          const data = res.data
          viewer.camera.setView({
            destination: new Cesium.Cartesian3(data.longitude, data.latitude, data.height), //相机位置
            orientation: {
              heading: data.heading,
              pitch: data.pitch,
              roll: data.roll
            }
          })
        }
      })
    }
  }, [viewer.camera])

  const showPositionModal = () => {
    setPositionModal({
      show: true
    })
  }

  const handleMeasureAction = (type: string) => {
    let drawingMode: DrawingMode = type === DRAW_TYPE.area ? 'polygon' : 'line'
    if (handler && handler.destroy) {
      entities.forEach(item => {
        // viewer.entities.removeById(item)
        viewer.entities.remove(item)
      })
      handler.destroy() // 关闭绘制事件句柄
      viewer.scene.globe.depthTestAgainstTerrain = false // 关闭地形深度检测
      setHandler(null)
    }
    if (current !== type) {
      if(type === DRAW_TYPE.volume) {
        setMeasureVolumeModal({
          show: true
        })
      } else {
        let handler = measure(viewer, drawingMode, (backShapePoints: any, backShapeEntities: any[]) => {
          setEntities(backShapeEntities)
        })
        setHandler(handler)
      }
      setCurrent(type)
    } else {
      setCurrent('') 
    }
  }

  useEffect(() => {
    flyToOrigin()
  }, [flyToOrigin])

  return (
    <div className={styles.toolbar}>
      <ul>
        <li onClick={showOriginModal} className={originModal.show ? styles.active : null}>
          <Icon component={SetOriginSvg} />
        </li>
        <li onClick={flyToOrigin}>
          <Icon component={FlyOriginSvg} />
        </li>
        <li onClick={showPositionModal} className={positionModal.show ? styles.active : null}>
          <Icon component={SetDecSvg} />
        </li>
        <li onClick={() => handleMeasureAction(DRAW_TYPE.horizontal)} className={current === DRAW_TYPE.horizontal ? styles.active : null}>
          <Icon component={HorMeasureSvg} />
        </li>
        <li onClick={() => handleMeasureAction(DRAW_TYPE.vertical)} className={current === DRAW_TYPE.vertical ? styles.active : null}>
          <Icon component={VerMeasureSvg} />
        </li>
        <li onClick={() => handleMeasureAction(DRAW_TYPE.area)} className={current === DRAW_TYPE.area? styles.active : null}>
          <Icon component={AreaMeasureSvg} />
        </li>
        <li onClick={() => handleMeasureAction(DRAW_TYPE.volume)} className={current === DRAW_TYPE.volume? styles.active : null}>
          <Icon component={volumeMeasureSvg} />
        </li>
      </ul>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
  originModal: state.home.originModal,
  positionModal: state.home.positionModal,
})

const mapDispatchToProps = {
  setOriginModal,
  setPositionModal,
  setMeasureVolumeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolBar)