import React, { useEffect, useState } from 'react'
import { Row, Col, Icon, Button, Divider, DatePicker, Checkbox, Input, Upload, Modal, message } from 'antd'
import Form, { FormComponentProps } from "antd/lib/form/Form"
import { connect } from 'react-redux'
import moment from 'moment'
import styles from './style.module.styl'
import { showStandingBook, showStandingBookAdd } from '../../actions'
import { createStandingBook, updateStandingBook } from '../../../api/home'

interface FormProps extends FormComponentProps {
  form: any
  token: any
  standingBook: any
  standingBookAdd: any
  showStandingBook: Function
  showStandingBookAdd: Function
}


export const MarkerStandingBookAdd = (props: FormProps) => {
  const { form, standingBook, showStandingBook, standingBookAdd, showStandingBookAdd, token } = props
  const { getFieldDecorator } = form
  const [fileList, setFileList] = useState([])
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState()
  const { marker_id, record = {} } = standingBookAdd
  const { id, title, is_show = 1, video, problem_time = new Date(), img = '' } = record
  let upload = img.split(',').map((item: string, index: any) => {
    return {
      uid: '-' + index,
      name: 'image.png',
      status: 'done',
      // @ts-ignore
      url: APP_BASE_API + '/resource/' + item
    }
  })

  upload = upload.filter((item: any) => {
    // @ts-ignore
    if (item.url !== APP_BASE_API + '/resource/') {
      return true
    }
  })

  const closeModal = () => {
    showStandingBook({
      show: true
    })
    showStandingBookAdd({
      show: false
    })
  }

  const handleVideoPreview = () => {

  }


  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    form.validateFields((err: any, values: any) => {
      if (!err) {
        // @ts-ignore
        const baseUrlLength = (APP_BASE_API + '/resource/').length
        const upload = values.upload.map((item: any) => {
          if (item.url) {
            return item.url.substring(baseUrlLength)
          }
          if (item.response && item.response.status === 1) {
            return item.response.data.img_url
          }
        })
        const img = upload.join(',')
        const img_num = upload.length

        const params: any = {
          id,
          marker_id: marker_id,
          title: values.title,
          problem_time: values.time.format('YYYY-MM-DD'),
          img: img,
          img_num: img_num,
          is_show: values.isShow ? 1 : 0,
          video: values.video,
        }

        if (id) {
          updateStandingBook(params).then((res: any) => {
            if (res.status === 1) {
              message.success('更新成功')
              closeModal()
            } else {
              message.error(res.message)
            }
          }).catch((err) => {
            message.error('更新失败')
          })
        } else {
          createStandingBook(params).then((res: any) => {
            if (res.status === 1) {
              message.success('创建成功')
              closeModal()
            } else {
              message.success(res.message)
            }
          }).catch((err) => {
            message.error('创建失败')
          })
        }

      }
    })
  }

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const handleChange = ({ fileList }: any) => {
    setFileList(fileList)
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">上传图片</div>
    </div>
  )

  return (
    <div className={styles.modal}>
      <header>
        <span>{standingBook.title}-台账</span>
        <Icon type="close" onClick={closeModal} />
      </header>
      <main>
        <div className={styles.cont}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onSubmit={handleSubmit} className={styles.form}>
            <Form.Item label="标题">
              {getFieldDecorator('title', {
                initialValue: title,
                rules: [{ required: true, message: '请输入台账标题!' }],
              })(<Input onMouseDown={e => e.stopPropagation()} />)}
            </Form.Item>
            <Row>
              <Col span={16}>
                <Form.Item label="问题时间" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                  {getFieldDecorator('time', {
                    initialValue: moment(problem_time, 'YYYY/MM/DD'),
                    rules: [{ required: false, message: '请选择问题时间!' }],
                  })(<DatePicker />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="报告可见" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                  {getFieldDecorator('isShow', {
                    valuePropName: 'checked',
                    initialValue: is_show === 1 ? true : false,
                    rules: [{ required: false, message: '请选择问题时间!' }],
                  })(<Checkbox />)}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="图片">
              {getFieldDecorator('upload', {
                initialValue: upload,
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
              })(
                <Upload
                  // @ts-ignore
                  action={`${APP_BASE_API}/api/upload-form/getloadfile`}
                  headers={{ token }}
                  name='UploadForm[imageFile]'
                  listType="picture-card"
                  accept="image/*"
                  onPreview={handlePreview}
                  onChange={handleChange}
                >
                  {fileList && fileList.length >= 4 ? null : uploadButton}
                </Upload>
              )}
            </Form.Item>
            <Form.Item label="视频">
              {getFieldDecorator('video', {
                initialValue: video,
                rules: [{ required: false, message: '请输入视频地址!' }],
              })(<Input onMouseDown={e => e.stopPropagation()} />)}
            </Form.Item>
            {/* <Row>
              <Col span={20}>
                <Form.Item label="视频" labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
                  {getFieldDecorator('video', {
                    rules: [{ required: false, message: '请输入视频地址!' }],
                  })(<Input onMouseDown={e => e.stopPropagation()} />)}
                </Form.Item>
              </Col>
              <Col span={4}>
                <div className="ant-form-item-control">
                  <Button type="link" onClick={handleVideoPreview}>预览</Button>
                </div>
              </Col>
            </Row> */}
            <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
              <Button type="primary" htmlType="submit" className={styles.submitBtn}>确定</Button>
            </Form.Item>
          </Form>
          <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
            <img alt="img" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  token: state.login.token,
  standingBook: state.home.standingBook,
  standingBookAdd: state.home.standingBookAdd,
})

const mapDispatchToProps = {
  showStandingBook,
  showStandingBookAdd
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create<FormProps>()(MarkerStandingBookAdd))
