import * as Cesium from 'cesium'
import convert from 'xml-js'
import * as turf from '@turf/turf'
import wkt from 'terraformer-wkt-parser'
import GeoJSON, { Point, LineString, Polygon } from 'geojson'

interface Cartesian3Object {
  type: string,
  positions: Cesium.Cartesian3[]
}

interface Wgs84Object {
  type: string,
  positions: [Cesium.Cartesian3[]] | Array<any>[]
}

export type DrawingMode = 'line' | 'polygon'

// geom转cesium坐标数组
export function geomToCartesian3(geom: string): Cartesian3Object {
  let positions: Cesium.Cartesian3[] = []
  let geojson = wkt.parse(geom)
  let { type, coordinates } = geojson as Point | LineString | Polygon
  if (type === 'Point') {
    let [x, y, z] = coordinates as number[]
    positions.push(Cesium.Cartesian3.fromDegrees(x, y, z))
  } else if (type === 'LineString') {
    coordinates.forEach((element: any) => {
      let [x, y, z] = element as number[]
      positions.push(Cesium.Cartesian3.fromDegrees(x, y, z))
    })
  } else if (type === 'Polygon') {
    coordinates.forEach((element: any) => {
      element.forEach((item: any) => {
        let [x, y, z] = item as number[]
        positions.push(Cesium.Cartesian3.fromDegrees(x, y, z))
      })
    })
  }
  return {
    type,
    positions
  }
}

// 点位转WKTString
export function positionToGeom(wgs84Obj: any) {
  const { type, positions } = wgs84Obj
  console.log(positions)
  let geom = ''
  if (type === 'Point') {
    geom = wkt.convert({
      "type": "Point",
      "coordinates": positions[0]
    })
  } else if (type === 'LineString') {
    geom = wkt.convert({
      "type": "LineString",
      "coordinates": positions
    })
  } else if (type === 'Polygon') {
    geom = wkt.convert({
      "type": "Polygon",
      "coordinates": [positions]
    })
  }
  console.log(geom)
  return geom
}

// cesium坐标转WGS84坐标
export function simpleCartesian3ToWGS84(viewer: Cesium.Viewer, position: Cesium.Cartesian3): Cesium.Cartesian3 {
  let ellipsoid = viewer.scene.globe.ellipsoid
  let wgs84 = ellipsoid.cartesianToCartographic(position)
  let lng: number, lat: number, alt: number
  //弧度转经度
  lng = Cesium.Math.toDegrees(wgs84.longitude)
  lat = Cesium.Math.toDegrees(wgs84.latitude)
  alt = wgs84.height
  return new Cesium.Cartesian3(lng, lat, alt)
}

// Cartesian3ToWGS84
export function cartesian3ToWGS84(viewer: Cesium.Viewer, cartesian3Obj: Cartesian3Object): Wgs84Object {
  const { type, positions } = cartesian3Obj
  let wgs84Positions
  if (type === 'Point') {
    wgs84Positions = Array.prototype.map.call(positions, (item: Cesium.Cartesian3) => {
      let wgs84 = simpleCartesian3ToWGS84(viewer, item)
      return [wgs84.x, wgs84.y, wgs84.z]
    })
  } else if (type === 'LineString') {
    wgs84Positions = Array.prototype.map.call(positions, (item: Cesium.Cartesian3) => {
      let wgs84 = simpleCartesian3ToWGS84(viewer, item)
      return [wgs84.x, wgs84.y, wgs84.z]
    })
  } else if (type === 'Polygon') {
    wgs84Positions = Array.prototype.map.call(positions, (item: Cesium.Cartesian3) => {
      let wgs84 = simpleCartesian3ToWGS84(viewer, item)
      return [wgs84.x, wgs84.y, wgs84.z]
    })
    wgs84Positions.push(wgs84Positions[0])
  }
  return {
    type,
    positions: wgs84Positions as [Cesium.Cartesian3[]] || [[]]
  }
}

// 获取实体中心点
export function computePositionsCenter(wgs84Obj: Wgs84Object) {
  const { type, positions } = wgs84Obj
  let turfPositions, positionCenter
  if (type === 'LineString') {
    turfPositions = turf.lineString(positions as turf.Position[])
    positionCenter = turf.centerOfMass(turfPositions)
  } else if (type === 'Polygon') {
    turfPositions = turf.polygon([positions as turf.Position[]])
    positionCenter = turf.centerOfMass(turfPositions) // 中心点计算
  }
  return positionCenter
}

// 颜色格式调整 
export function agbrToRgba(color: string) {
  return (color.substring(2, 8) + color.substring(0, 2))
}
// 颜色格式调整 
export function hexArgbToHexAbgr(color: string) {
  return `${color.slice(0, 2)}${color.slice(6, 8)}${color.slice(4, 6)}${color.slice(2, 4)}`
}

// 颜色转换
export function fromCssColorString(param: { r: string, g: string, b: string, a: string } | string) {
  if (typeof (param) === 'object') {
    const { r, g, b, a } = param
    return Cesium.Color.fromCssColorString(`rgba(${r}, ${g}, ${b}, ${a})`)
  } else {
    let r = Number.parseInt(param.substring(0, 2), 16)
    let g = Number.parseInt(param.substring(2, 4), 16)
    let b = Number.parseInt(param.substring(4, 6), 16)
    let a = Number.parseInt(param.substring(6, 8), 16)
    return Cesium.Color.fromBytes(r, g, b, a)
  }
}

export function getRandomColor() {
  const colors = [
    '8a2be250', 'ff7f5050', 'dc143c50', '008b8b50',
    '1e90ff50', 'adff2f50', 'ffa50050', 'db709350',
    'ff634750', '48d1cc50', 'f0e68c50', '6495ed50',
    '9370db50', 'da70d650', '2e8b5750', 'ff7f5050'
  ]
  return colors[Math.floor(Math.random() * colors.length)]
}


// 创建Cesium实体
export const createPolygon = (viewer: Cesium.Viewer, options: { text: string, color: string, geom: string }) => {
  const { text, geom, color } = options
  // geom转Cartesian3
  let cartesian3Obj = geomToCartesian3(geom)
  // 坐标转换
  let wgs84Obj = cartesian3ToWGS84(viewer, cartesian3Obj)
  // 获取中心点
  let positionsCenter = computePositionsCenter(wgs84Obj)

  // @ts-ignore
  let entity = new Cesium.Entity({
    // @ts-ignore
    position: Cesium.Cartesian3.fromDegrees(positionsCenter.geometry.coordinates[0], positionsCenter.geometry.coordinates[1], wgs84Obj.positions[0][2]),
    polygon: {
      // @ts-ignore
      hierarchy: cartesian3Obj.positions,
      material: fromCssColorString(color),
      // @ts-ignore
      clampToGround: true
    },
    label: {
      text: text,
      font: '14pt monospace',
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian2(0, 0)
    }
  })

  viewer.entities.add(entity)

  return entity
}

export const createBillboard = (viewer: Cesium.Viewer, options: { position: Cesium.Cartesian3, text: string, image: any }) => {
  const { position, text, image } = options
  // @ts-ignore
  let entity = new Cesium.Entity({
    position: position,
    billboard: {
      image: image,
      // @ts-ignore
      width: 32,
      // @ts-ignore
      height: 32,
      // scale: 0.6,
      // eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -64.0)
      // @ts-ignore
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0.0, 5000),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
    },
    label: {
      // @ts-ignore
      text: text,
      font: '14pt monospace',
      // @ts-ignore
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      // @ts-ignore
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian3(0.0, 32, 0.0),
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0.0, 5000),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      // @ts-ignore
      zIndex: 5000
    },
  })
  viewer.entities.add(entity)
  return entity
}

export const createPolyline = (viewer: Cesium.Viewer, options: { positions: Cesium.Cartesian3[], color?: Cesium.Color, width?: number }) => {
  const { positions, color = Cesium.Color.YELLOWGREEN, width = 5 } = options
  let entity = new Cesium.Entity({
    polyline: new Cesium.PolylineGraphics({
      positions: positions,
      width: width,
      // @ts-ignore
      material: color,
      // @ts-ignore
      clampToGround: true,
      zIndex: 5000
    })
  })
  viewer.entities.add(entity)
  return entity
}

export const createGeomToPoint = (viewer: Cesium.Viewer, geom: string, image: any, title: string) => {
  const { positions } = geomToCartesian3(geom)
  // @ts-ignore
  let entity = new Cesium.Entity({
    position: positions[0],
    billboard: {
      image: image,
      // @ts-ignore
      width: 32,
      // @ts-ignore
      height: 32,
      // scale: 0.6,
      // eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -64.0)
      // @ts-ignore
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0.0, 5000),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
    },
    label: {
      // @ts-ignore
      text: title,
      font: '14pt monospace',
      // @ts-ignore
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      // @ts-ignore
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian3(0.0, 32, 0.0),
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0.0, 5000),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      // @ts-ignore
      zIndex: 5000
    },
  })
  viewer.entities.add(entity)
  return entity
}

export const createGeomToPolyline = (viewer: Cesium.Viewer, geom: string, style: string, title: string) => {
  const styleObj = JSON.parse(style)
  const styleJson = convert.xml2json(styleObj.GeoSymbolXml, { compact: true, spaces: 4 })
  const styles = JSON.parse(styleJson)
  const color = '0x' + hexArgbToHexAbgr(styles.CurveSymbol._attributes.Color)
  const width = Math.abs(styles.CurveSymbol._attributes.Width)
  const clampToGround = styleObj.HeightStyle !== '1'
  const cartesian3Obj = geomToCartesian3(geom)  // geom转Cartesian3
  const { positions } = cartesian3Obj
  // const wgs84Obj = cartesian3ToWGS84(viewer, cartesian3Obj) // 坐标转换
  // const positionsCenter = computePositionsCenter(wgs84Obj)  // 获取中心点
  // 获取中心点
  console.log(cartesian3Obj)
  const polyCenter = Cesium.BoundingSphere.fromPoints(positions).center
  console.log(polyCenter)
  // @ts-ignore
  const entity = new Cesium.Entity({
    // @ts-ignore
    // position: Cesium.Cartesian3.fromDegrees(positionsCenter.geometry.coordinates[0], positionsCenter.geometry.coordinates[1], positions[0][2]),
    position: polyCenter,
    label: {
      // @ts-ignore
      text: title,
      font: '14pt monospace',
      // @ts-ignore
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -10),
      // @ts-ignore
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      // @ts-ignore
      pixelOffset: new Cesium.Cartesian2(0, 0),
      // clampToGround: clampToGround,
      // disableDepthTestDistance: Number.POSITIVE_INFINITY
    },
    polyline: {
      // @ts-ignore
      positions: positions,
      width: width,
      // @ts-ignore
      material: Cesium.Color.fromRgba(color),
      // @ts-ignore
      clampToGround: clampToGround,
      zIndex: 5000
    },
  })
  viewer.entities.add(entity)
  return entity
}

export const createGeomToPolygon = (viewer: Cesium.Viewer, geom: string, style: string, title: string) => {
  const styleObj = JSON.parse(style)
  const styleJson = convert.xml2json(styleObj.GeoSymbolXml, { compact: true, spaces: 4 })
  const styles = JSON.parse(styleJson)
  const color = '0x' + hexArgbToHexAbgr(styles.SurfaceSymbol._attributes.Color)
  const outlineColor = '0x' + hexArgbToHexAbgr(styles.SurfaceSymbol.CurveSymbol._attributes.Color)
  const width = Math.abs(styles.SurfaceSymbol.CurveSymbol._attributes.Width)
  const clampToGround = styleObj.HeightStyle !== '1'
  const cartesian3Obj = geomToCartesian3(geom)  // geom转Cartesian3
  const { positions } = cartesian3Obj
  const wgs84Obj = cartesian3ToWGS84(viewer, cartesian3Obj) // 坐标转换
  const positionsCenter = computePositionsCenter(wgs84Obj)  // 获取中心点
  // const polyCenter = Cesium.BoundingSphere.fromPoints(positions).center
  // console.log(color, outlineColor, width)
  // @ts-ignore
  const point = Cesium.Cartographic.fromDegrees(positionsCenter.geometry.coordinates[0], positionsCenter.geometry.coordinates[1])
  // @ts-ignore
  const height = viewer.scene.sampleHeight(point)

  // @ts-ignore
  const entity = new Cesium.Entity({
    // @ts-ignore
    position: Cesium.Cartesian3.fromDegrees(positionsCenter.geometry.coordinates[0], positionsCenter.geometry.coordinates[1], height),
    // position: polyCenter,
    label: {
      text: title,
      font: '14pt monospace',
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -10),
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      // eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 32.0),
      pixelOffset: new Cesium.Cartesian2(0, 0),
      disableDepthTestDistance: Number.POSITIVE_INFINITY
    },
    polygon: {
      // @ts-ignore
      hierarchy: positions,
      // material: fromCssColorString(color),
      // @ts-ignore
      material: Cesium.Color.fromRgba(color),
      outline: true,
      outlineWidth: width,
      // outlineColor: fromCssColorString(outlineColor),
      // @ts-ignore
      outlineColor: Cesium.Color.fromRgba(outlineColor),
      // @ts-ignore
      clampToGround: clampToGround,
      perPositionHeight: !clampToGround
    },
  })
  viewer.entities.add(entity)
  return entity
}


//空间两点距离计算函数
export function getSpaceDistance(positions: any) {
  let distance = 0;
  for (let i = 0; i < positions.length - 1; i++) {

    // @ts-ignore
    let point1cartographic = Cesium.Cartographic.fromCartesian(positions[i]);
    // @ts-ignore
    let point2cartographic = Cesium.Cartographic.fromCartesian(positions[i + 1]);
    /**根据经纬度计算出距离**/
    let geodesic = new Cesium.EllipsoidGeodesic();
    geodesic.setEndPoints(point1cartographic, point2cartographic);
    let s = geodesic.surfaceDistance;
    //console.log(Math.sqrt(Math.pow(distance, 2) + Math.pow(endheight, 2)));
    //返回两点之间的距离
    s = Math.sqrt(Math.pow(s, 2) + Math.pow(point2cartographic.height - point1cartographic.height, 2));
    distance = distance + s;
  }
  return distance.toFixed(2);
}

//空间面积计算
export function getSpaceArea(positions: any) {
  let col = getCoordinates(positions)
  let newCol = col.concat(col.slice(0, 1))
  let polygongeojson = turf.polygon([newCol])
  let area = turf.area(polygongeojson)
  return area.toFixed(2)
}

const getCoordinates = (t: any) => {
  let e = []
  for (let i = 0; i < t.length; i++) {
    //@ts-ignore
    let n = Cesium.Cartographic.fromCartesian(t[i]),
      r = Number(Cesium.Math.toDegrees(n.longitude).toFixed(6)),
      o = Number(Cesium.Math.toDegrees(n.latitude).toFixed(6)),
      a = Number(n.height.toFixed(1));
    e.push([r, o, a])
  }
  return e
}

// 以下三个方法为官方绘制demo
const createPoint = (viewer: Cesium.Viewer, drawingMode: DrawingMode, worldPosition: any, activeShapePoints: any) => {
  let distance = 0
  let textDisance = ''
  if (activeShapePoints.length > 2 && drawingMode === 'line') {
    // @ts-ignore
    distance = getSpaceDistance(activeShapePoints)
    textDisance = distance + "米";
  }

  let point = viewer.entities.add({
    position: worldPosition,
    point: {
      // @ts-ignore
      color: Cesium.Color.RED,
      // @ts-ignore
      pixelSize: 5,
      // @ts-ignore
      // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND // 开启后点贴地
    },
    label: {
      // @ts-ignore
      text: textDisance,
      font: '18px sans-serif',
      fillColor: Cesium.Color.GOLD,
      // @ts-ignore
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      // @ts-ignore
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      // @ts-ignore
      pixelOffset: new Cesium.Cartesian2(20, -20),
    }
  })
  return point
}

const createLabel = (viewer: Cesium.Viewer, positionData: any) => {
  const col = getCoordinates(positionData)
  const newCol = col.concat(col.slice(0, 1))
  const polygongeojson = turf.polygon([newCol])
  const area = turf.area(polygongeojson)
  const textArea = `${area.toFixed(2)}平方米` 
  // const centerPoint = turf.centerOfMass(polygongeojson)
  // 获取中心点
  const polyCenter = Cesium.BoundingSphere.fromPoints(positionData).center
  console.log(polyCenter)

  let label = viewer.entities.add({
    name: '多边形面积',
    // @ts-ignore
    // position: Cesium.Cartesian3.fromDegrees(centerPoint.geometry.coordinates[0], centerPoint.geometry.coordinates[1]),
    position: polyCenter,
    label: {
      // @ts-ignore
      text: textArea,
      font: '18px sans-serif',
      fillColor: Cesium.Color.GOLD,
      // @ts-ignore
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -10),
      // @ts-ignore
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian2(0, 0),
      disableDepthTestDistance: Number.POSITIVE_INFINITY
    }
  })
  return label
}

export const drawShape = (viewer: Cesium.Viewer, drawingMode: DrawingMode, positionData: any) => {
  let shape
  if (drawingMode === 'line') {
    shape = viewer.entities.add({
      // @ts-ignore
      polyline: {
        positions: positionData,
        // @ts-ignore
        // clampToGround: true,
        material: Cesium.Color.YELLOW.withAlpha(0.4),
        width: 3
      }
    })
  }
  else if (drawingMode === 'polygon') {
    shape = viewer.entities.add({
      // @ts-ignore
      polygon: {
        hierarchy: positionData,
        material: new Cesium.ColorMaterialProperty(Cesium.Color.YELLOW.withAlpha(0.4)),
        // @ts-ignore
        // clampToGround: false,
      }
    })
  }
  return shape
}

export const measure = function (viewer: Cesium.Viewer, drawingMode: string, callback: Function) {
  if (!viewer.scene.pickPositionSupported) {
    window.alert("This browser does not support pickPosition.")
  }

  viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
    Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
  )

  let activeShapePoints: any[] = []
  let activeShape: any
  let floatingPoint: any
  let backShapePoints: any[] = []
  let backShapeEntities: any[] = []

  viewer.scene.globe.depthTestAgainstTerrain = true // 开启地形深度检测
  let handler: any = new Cesium.ScreenSpaceEventHandler(viewer.canvas)
  handler.setInputAction(function (event: any) {
    // We use `viewer.scene.pickPosition` here instead of `viewer.camera.pickEllipsoid` so that
    // we get the correct point when mousing over terrain.
    // let earthPosition = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid)
    let earthPosition = viewer.scene.pickPosition(event.position)
    console.log(earthPosition)

    // `earthPosition` will be undefined if our mouse is not over the globe.
    if (Cesium.defined(earthPosition)) {
      if (activeShapePoints.length === 0) {
        floatingPoint = createPoint(earthPosition)
        // @ts-ignore
        activeShapePoints.push(earthPosition)
        let dynamicPositions = new Cesium.CallbackProperty(function () {
          if (drawingMode === "polygon") {
            return new Cesium.PolygonHierarchy(activeShapePoints)
          }
          return activeShapePoints
        }, false)
        activeShape = drawShape(dynamicPositions)
      }
      // @ts-ignore
      activeShapePoints.push(earthPosition)
      // createPoint(earthPosition)
      createPoint(earthPosition, activeShapePoints)
    }
  }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

  handler.setInputAction(function (event: any) {
    if (Cesium.defined(floatingPoint)) {
      let newPosition = viewer.scene.pickPosition(event.endPosition)
      if (Cesium.defined(newPosition)) {
        floatingPoint.position.setValue(newPosition)
        activeShapePoints.pop()
        // @ts-ignore
        activeShapePoints.push(newPosition)
      }
    }
  }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

  handler.setInputAction(function (event: any) {
    terminateShape()
    // viewer.scene.globe.depthTestAgainstTerrain = false // 关闭地形深度检测
    callback(backShapePoints, backShapeEntities)
    // handler.destroy() //关闭事件句柄
    // handler = null
  }, Cesium.ScreenSpaceEventType.RIGHT_CLICK)

  function createPoint(worldPosition: any, activeShapePoints?: any) {
    let point
    if(drawingMode === 'line' && Array.isArray(activeShapePoints)) {
      let distance = getSpaceDistance(activeShapePoints)
      point = viewer.entities.add({
        position: worldPosition,
        point: {
          color: Cesium.Color.RED,
          pixelSize: 5,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        },
        label: {
          // @ts-ignore
          text: `${distance}米`,
          font: '18px sans-serif',
          fillColor: Cesium.Color.GOLD,
          // @ts-ignore
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          outlineWidth: 2,
          // @ts-ignore
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          // @ts-ignore
          pixelOffset: new Cesium.Cartesian2(20, -20),
        }
      })
    } else {
      point = viewer.entities.add({
        position: worldPosition,
        point: {
          color: Cesium.Color.RED,
          pixelSize: 5,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        }
      })
    }
    // @ts-ignore
    backShapeEntities.push(point)
    return point
  }

  // Redraw the shape so it's not dynamic and remove the dynamic shape.
  function terminateShape() {
    activeShapePoints.pop()
    drawShape(activeShapePoints)
    viewer.entities.remove(floatingPoint)
    viewer.entities.remove(activeShape)
    floatingPoint = undefined
    activeShape = undefined
    activeShapePoints = []
  }

  function drawShape(positionData: any) {
    backShapePoints = positionData
    let shape, label
    if (drawingMode === "line") {
      shape = viewer.entities.add({
        polyline: {
          positions: positionData,
          material: Cesium.Color.YELLOW.withAlpha(0.4),
          clampToGround: true,
          width: 3,
        },
      })
    } else if (drawingMode === "polygon") {
      shape = viewer.entities.add({
        polygon: {
          hierarchy: positionData as any,
          material: new Cesium.ColorMaterialProperty(
            Cesium.Color.YELLOW.withAlpha(0.4)
          )
        },
      })
    }

    if (drawingMode === "polygon" && Array.isArray(positionData)) {
      const wgs84Obj = cartesian3ToWGS84(viewer, {
        type: 'Polygon',
        positions: positionData
      })
      // 获取中心点
      const positionsCenter = computePositionsCenter(wgs84Obj)
      // @ts-ignore
      const point = Cesium.Cartographic.fromDegrees(positionsCenter.geometry.coordinates[0], positionsCenter.geometry.coordinates[1])
      // @ts-ignore
      const height = viewer.scene.sampleHeight(point)
      // @ts-ignore
      const polyCenter = Cesium.Cartesian3.fromDegrees(positionsCenter.geometry.coordinates[0], positionsCenter.geometry.coordinates[1], height)
      // const polyCenter = Cesium.BoundingSphere.fromPoints(positionData).center
      
      const col = getCoordinates(positionData)
      const newCol = col.concat(col.slice(0, 1))
      const polygongeojson = turf.polygon([newCol])
      const area = turf.area(polygongeojson)
      // console.log(area)
      
      label = viewer.entities.add({
        position: polyCenter,
        label: {
          // @ts-ignore
          text: `${area.toFixed(2)}平方米`,
          font: '18px sans-serif',
          fillColor: Cesium.Color.GOLD,
          // @ts-ignore
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          outlineWidth: 2,
          // @ts-ignore
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          // @ts-ignore
          pixelOffset: new Cesium.Cartesian2(20, -20),
          eyeOffset: new Cesium.Cartesian3(0, 0, -10)
        }
      }) 
    }
    // @ts-ignore
    backShapeEntities.push(shape)
    // @ts-ignore
    backShapeEntities.push(label)
    return shape
  }

  return handler
}

export const measure2 = (viewer: Cesium.Viewer, drawingMode: DrawingMode, callback?: Function) => {
  let activeShapePoints: any[] = []
  let activeShape: any
  let floatingPoint: any
  let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas)
  let entities: any[] = []
  // 解决viewer.scene.pickPosition(e.position)在没有3dTile模型下的笛卡尔坐标不准问题。
  // 解决方法：viewer.scene.globe.depthTestAgainstTerrain = true; // 默认为false
  // 别人的试验结论：
  // 1. globe.pick的结果相对稳定准确，不论地形深度检测开启与否，不论加载的是默认地形还是别的地形数据；
  // 2. scene.pickPosition只有在开启地形深度检测，且不使用默认地形时是准确的。
  // viewer.scene.globe.depthTestAgainstTerrain = true

  handler.setInputAction((event) => {
    // @ts-ignore
    if (!Cesium.Entity.supportsPolylinesOnTerrain(viewer.scene)) {
      console.log('This browser does not support polylines on terrain.')
      return
    }
    // We use `viewer.scene.pickPosition` here instead of `viewer.camera.pickEllipsoid` so that
    // we get the correct point when mousing over terrain.
    let earthPosition = viewer.scene.pickPosition(event.position)
    // let ray = viewer.camera.getPickRay(event.position);
    // let earthPosition = viewer.scene.globe.pick(ray, viewer.scene);
    const wgs84 = simpleCartesian3ToWGS84(viewer, earthPosition)
    if (wgs84.z < 0) {
      // @ts-ignore
      earthPosition = viewer.scene.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid)
    }
    // `earthPosition` will be undefined if our mouse is not over the globe.
    if (Cesium.defined(earthPosition)) {
      if (activeShapePoints.length === 0) {
        floatingPoint = createPoint(viewer, drawingMode, earthPosition, activeShapePoints)
        activeShapePoints.push(earthPosition)
        let dynamicPositions = new Cesium.CallbackProperty(function () {
          if (drawingMode === 'polygon') {
            return new Cesium.PolygonHierarchy(activeShapePoints)
          }
          return activeShapePoints
        }, false)
        activeShape = drawShape(viewer, drawingMode, dynamicPositions)
      }
      activeShapePoints.push(earthPosition)
      let point = createPoint(viewer, drawingMode, earthPosition, activeShapePoints)
      entities.push(point.id)
    }
  }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

  handler.setInputAction(function (event) {
    if (Cesium.defined(floatingPoint)) {
      let newPosition = viewer.scene.pickPosition(event.endPosition)
      // let ray = viewer.camera.getPickRay(event.endPosition);
      // let newPosition = viewer.scene.globe.pick(ray, viewer.scene);
      const wgs84 = simpleCartesian3ToWGS84(viewer, newPosition)
      if (wgs84.z < 0) {
        // @ts-ignore
        newPosition = viewer.scene.camera.pickEllipsoid(event.endPosition, viewer.scene.globe.ellipsoid)
      }
      // let newPosition = viewer.scene.camera.pickEllipsoid(event.endPosition, viewer.scene.globe.ellipsoid)
      if (Cesium.defined(newPosition)) {
        floatingPoint.position.setValue(newPosition)
        activeShapePoints.pop()
        activeShapePoints.push(newPosition)
      }
    }
  }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

  // Redraw the shape so it's not dynamic and remove the dynamic shape.
  function terminateShape() {
    activeShapePoints.pop()
    let shape = drawShape(viewer, drawingMode, activeShapePoints)
    shape && entities.push(shape.id)
    viewer.entities.remove(floatingPoint)
    viewer.entities.remove(activeShape)
    floatingPoint = undefined
    activeShape = undefined
    activeShapePoints = []
    // handler.destroy() // 关闭事件句柄
  }

  handler.setInputAction(function (event) {
    if (drawingMode === 'polygon') {
      let label = createLabel(viewer, activeShapePoints)
      entities.push(label.id)
    }
    terminateShape()
    if (callback) {
      callback(handler, entities)
    }
  }, Cesium.ScreenSpaceEventType.RIGHT_CLICK)
}


// 标注相关
export const createLabelPolyline = (viewer: Cesium.Viewer, options: { text: string, positions: Cesium.Cartesian3[], color?: string, width?: number }) => {
  const { positions, text, color, width = 5 } = options
  // 获取中心点
  const polyCenter = Cesium.BoundingSphere.fromPoints(positions).center
  const entity = new Cesium.Entity({
    position: polyCenter,
    label: {
      // @ts-ignore
      text: text,
      font: '14pt monospace',
      // @ts-ignore
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -10),
      // @ts-ignore
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      // @ts-ignore
      pixelOffset: new Cesium.Cartesian2(0, 0)
    },
    polyline: new Cesium.PolylineGraphics({
      positions: positions,
      width: width,
      // @ts-ignore
      material: color ? fromCssColorString(color) : Cesium.Color.YELLOWGREEN,
      // @ts-ignore
      clampToGround: true,
      zIndex: 5000
    })
  })
  viewer.entities.add(entity)
  return entity
}

export const createLabelPolygon = (viewer: Cesium.Viewer, options: { text: string, color: string, positions: Cesium.Cartesian3[] }) => {
  const { text, positions, color } = options
  // geom转Cartesian3
  // let cartesian3Obj = geomToCartesian3(geom)
  // 坐标转换
  const wgs84Obj = cartesian3ToWGS84(viewer, {
    type: 'Polygon',
    positions
  })
  // 获取中心点
  // const polyCenter = Cesium.BoundingSphere.fromPoints(positions).center
  const positionsCenter = computePositionsCenter(wgs84Obj)
  // @ts-ignore
  const point = Cesium.Cartographic.fromDegrees(positionsCenter.geometry.coordinates[0], positionsCenter.geometry.coordinates[1])
  // @ts-ignore
  const height = viewer.scene.sampleHeight(point)

  // @ts-ignore
  const entity = new Cesium.Entity({
    // @ts-ignore
    position: Cesium.Cartesian3.fromDegrees(positionsCenter.geometry.coordinates[0], positionsCenter.geometry.coordinates[1], height),
    // position: polyCenter,
    label: {
      text: text,
      font: '14pt monospace',
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -10),
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian2(0, 0),
      disableDepthTestDistance: Number.POSITIVE_INFINITY
    },
    polygon: {
      // @ts-ignore
      hierarchy: positions,
      // perPositionHeight: true,
      material: fromCssColorString(color),
      outline: true,
      outlineColor: Cesium.Color.BLACK,
      // @ts-ignore
      clampToGround: true,
      // @ts-ignore
      perPositionHeight: false
    },
  })

  viewer.entities.add(entity)

  return entity
}

/**
 * 32位色值转rgba
 * @param {*} hex 例如：ffff0000 (顺序：argb)
 * @returns
 */
export function hexToRgba(hex: any) {
  return {
    a: parseInt("0x" + hex.slice(0, 2)) / 255,
    r: parseInt("0x" + hex.slice(2, 4)),
    g: parseInt("0x" + hex.slice(4, 6)),
    b: parseInt("0x" + hex.slice(6, 8)),
  }
}


/**
 * rgba转32位色值
 * @param rgba { r: 255, g: 0, b: 0, a: 1 }
 * @returns hex 例如：ffff0000 (顺序：argb)
 */
export function rgbaToHex(rgba: any) {
  let r, g, b, a
  a = ('0' + Math.floor(rgba.a * 255).toString(16)).slice(-2)
  r = ('0' + parseInt(rgba.r).toString(16)).slice(-2)
  g = ('0' + parseInt(rgba.g).toString(16)).slice(-2)
  b = ('0' + parseInt(rgba.b).toString(16)).slice(-2)
  return (a + r + g + b).toUpperCase()
}

// 根据经纬度获取高度
export function getHeigthByLonLat(viewer: Cesium.Viewer, lon = 87.5968, lat = 43.8084) {
  // viewer.scene.globe.depthTestAgainstTerrain = true // 开启地形深度检测
  const positions = Cesium.Cartographic.fromDegrees(lon, lat)
  console.log(positions)
  var height = viewer.scene.sampleHeight(positions)
  // var height = viewer.scene.globe.getHeight(positions)
  console.log(height)
  // let promise = viewer.scene.sampleHeightMostDetailed([positions]);
  // promise.then(function (updatedPosition) {
  //   // positions[0].height and positions[1].height have been updated.
  //   // updatedPositions is just a reference to positions.
  //   console.log("zhang", lon, lat, updatedPosition[0].height) 
  // })
  // @ts-ignore
  // let promise = new Cesium.sampleTerrain(viewer.terrainProvider, 13, [positions])
  // // @ts-ignore
  // promise = Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, positions)
  // // @ts-ignore
  // Cesium.when(new Cesium.sampleTerrain(viewer.terrainProvider, 13, [positions]), function (updatedPositions) {
  // 	console.log("zhang",lon,lat,updatedPositions[0].height)
  // })
}

// 获取XML style
export function getXMLStyle(type: string, width: string, height: number, hex: string, fillHex?: string) {
  let cmObj, cmXml, style = ''
  if (type === 'polyline') {
    cmObj = {
      CurveSymbol: {
        _attributes: {
          Color: hex,
          RepeatLength: "1.000000",
          Width: width,
          DashStyle: "DashSolid",
        }
      }
    }
  } else if (type === 'polygon') {
    cmObj = {
      SurfaceSymbol: {
        _attributes: {
          Color: fillHex,
          EnableLight: "false",
          RepeatLengthU: "1.000000",
          RepeatLengthV: "1.000000",
          Rotation: "0.000000",
        },
        CurveSymbol: {
          _attributes: {
            Color: hex,
            RepeatLength: "1.000000",
            Width: width,
            DashStyle: "DashSolid",
          }
        }
      }
    }
  }
  // @ts-ignore
  cmXml = convert.js2xml(cmObj, { compact: true, spaces: 4 })
  style = JSON.stringify({
    HeightStyle: height,
    GeoSymbolXml: cmXml,
    TextSymbolXml: ""
  })
  return style
}


/**
 * 方量计算中使用
 * @param points 顶点坐标
 */
//计算多边形面积
export function getArea(points: any) {

  var radiansPerDegree = Math.PI / 180.0;//角度转化为弧度(rad)
  var degreesPerRadian = 180.0 / Math.PI;//弧度转化为角度

  /*角度*/
  function Angle(p1: any, p2: any, p3: any) {
    var bearing21 = Bearing(p2, p1);
    var bearing23 = Bearing(p2, p3);
    var angle = bearing21 - bearing23;
    if (angle < 0) {
      angle += 360;
    }
    return angle;
  }

  /*方向*/
  function Bearing(from: any, to: any) {
    // @ts-ignore
    from = Cesium.Cartographic.fromCartesian(from);
    // @ts-ignore
    to = Cesium.Cartographic.fromCartesian(to);

    var lat1 = from.latitude;
    var lon1 = from.longitude;
    var lat2 = to.latitude;
    var lon2 = to.longitude;
    var angle = -Math.atan2(Math.sin(lon1 - lon2) * Math.cos(lat2), Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2));
    if (angle < 0) {
      angle += Math.PI * 2.0;
    }
    angle = angle * degreesPerRadian;//角度
    return angle;
  }

  function distance(point1: any, point2: any) {
    // @ts-ignore
    var point1cartographic = Cesium.Cartographic.fromCartesian(point1);
    // @ts-ignore
    var point2cartographic = Cesium.Cartographic.fromCartesian(point2);
    /**根据经纬度计算出距离**/
    var geodesic = new Cesium.EllipsoidGeodesic();
    geodesic.setEndPoints(point1cartographic, point2cartographic);
    var s = geodesic.surfaceDistance;
    //console.log(Math.sqrt(Math.pow(distance, 2) + Math.pow(endheight, 2)));
    //返回两点之间的距离
    s = Math.sqrt(Math.pow(s, 2) + Math.pow(point2cartographic.height - point1cartographic.height, 2));
    return s;
  }

  var res = 0;
  //拆分三角曲面

  for (var i = 0; i < points.length - 2; i++) {
    var j = (i + 1) % points.length;
    var k = (i + 2) % points.length;
    var totalAngle = Angle(points[i], points[j], points[k]);


    var dis_temp1 = distance(points[j], points[0]);
    var dis_temp2 = distance(points[k], points[0]);
    res += dis_temp1 * dis_temp2 * Math.sin(totalAngle) / 2;
    // console.log(res);
  }

  // if (res < 1000000) {
  //   // @ts-ignore
  //   res = Math.abs(res).toFixed(4) + " 平方米";
  // } else {
  //   // @ts-ignore
  //   res = Math.abs((res / 1000000.0).toFixed(4)) + " 平方公里";
  // }

  return Math.abs(res);

};