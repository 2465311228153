import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { message } from 'antd'
import { view as Login } from '../login'
import { getOrigin } from '../api/home'
import { loginFailure } from '../login/actions'

const mapStateToProps = (state: any) => ({
  isLogin: state.login.isLogin
})

const mapDispatchToProps = {
  loginFailure
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = (props: any) => {
  const { isLogin, loginFailure, component, ...options } = props

  const finalComponent = isLogin ? component : Login

  useEffect(() => {
    if (isLogin && options.path !== '/' && options.path !== '/login') {
      getOrigin().then((res: any) => {
        if (res.status !== 1) {
          loginFailure()
          message.error('用户信息过期，请重新登录')
        }
      }).catch((err) => {
        loginFailure()
        message.error('用户信息过期，请重新登录')
      })
    }
  })

  return (
    <Route {...options} component={finalComponent} />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)