import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styles from './style.module.styl'
import { Table } from 'antd'

const UavDataStatistics = (props: any) => {
  const { data } = props
  const [color, setColor] = useState();

  const columns = [
    {
      title: '单位名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '姓名',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '常驻地',
      dataIndex: 'address',
      key: 'address',
    },
  ];

  useEffect(() => {
    console.log(props)
  })

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>无人机数据统计</div>
      <div className={styles.content}>
        <Table columns={columns} dataSource={data} pagination={{simple: true}}/>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    status: state.weather.status,
  }
}

export default connect(mapStateToProps)(UavDataStatistics)