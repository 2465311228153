import * as React from 'react'
import * as Cesium from 'cesium'
import { Icon, Button, Spin } from 'antd'
import styles from './style.module.styl'
import { connect } from 'react-redux'
import moment from 'moment'
import { setCalendarModal, setEntities } from '../../actions'
import { getUserWorkDay, getUserTrack, getUserHome } from '../../../api/home'
import { 
  geomToCartesian3, 
  createPolygon,
  createBillboard,
  createPolyline
} from '../../../utils/CesiumTool'
import signInIcon from '../../../static/icons/alphabet_C.png'
import startIcon from '../../../static/icons/alphabet_S.png'
import endIcon from '../../../static/icons/alphabet_E.png'
import { USER_TRACK } from '../../entitiesKey'

moment.locale('zh-cn')
const { useState, useEffect } = React

type WeekTitle = {
  title: string
  inner: string
}

const weekTitles: WeekTitle[] = [
  {
    title: '周日',
    inner: '日'
  },
  {
    title: '周一',
    inner: '一'
  },
  {
    title: '周二',
    inner: '二'
  },
  {
    title: '周三',
    inner: '三'
  },
  {
    title: '周四',
    inner: '四'
  },
  {
    title: '周五',
    inner: '五'
  },
  {
    title: '周六',
    inner: '六'
  }
]
const arr6 = new Array(6).fill('')
const arr7 = new Array(7).fill('')

const CalendarModal = (props: any) => {
  console.log(props)
  const { viewer, entities, setCalendarModal, setEntities } = props
  const { name, phone } = props.calendarModal
  const [date, setDate] = useState<moment.Moment>(moment())
  const [year, setYear] = useState(date.year())
  const [month, setMonth] = useState(date.month())
  const [dates, setDates] = useState<any[]>([])
  const [workDay, setWorkDay] = useState<any[]>([])
  const [selectedDay, setSelectedDay] = useState<moment.Moment>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setDate(moment())
    getMonthDay(moment())
  }, [phone])

  useEffect(() => {
    setLoading(true)
    getUserWorkDay({
      phone: phone,
      ym: date.format('YYYY-MM')
    }).then(res => {
      setWorkDay(res.data)
      setLoading(false)
    })
  }, [year, month, phone, date])

  const getMonthDay = (date: Date | moment.Moment) => {
    const dates: any[] = []
    for (let i = 0; i < 42; i += 1) {
      const startDate = moment(date).date(1);
      // dates[i] = startDate.weekday(i).date();
      dates[i] = startDate.weekday(i)
    }
    setYear(moment(date).year())
    setMonth(moment(date).month())
    setDates(dates)
  }

  const hideModal = () => {
    setCalendarModal({
      show: false
    })
    clearEntities(USER_TRACK)
  }

  const clearEntities = (key: string) => {
    if (entities[key]) {
      entities[key].forEach((element: string) => {
        viewer.entities.removeById(element)
      })
    }
  }

  const handlePreYear = () => {
    getMonthDay(date.subtract(1, 'year'))
  }

  const handleNextYear = () => {
    getMonthDay(date.add(1, 'year'))
  }

  const handlePreMonth = () => {
    getMonthDay(date.subtract(1, 'month'))
  }

  const handleNextMonth = () => {
    getMonthDay(date.add(1, 'month'))
  }

  const handleSelectedDay = (curDate: moment.Moment) => {
    setSelectedDay(curDate)
  }

  const handleShowUserTrack = async () => {
    clearEntities(USER_TRACK)
    const trackData = await getUserTrack({
      phones: phone,
      date: moment(selectedDay).format('YYYY-MM-DD')
    })
    // .then(res => {
    //   if (res.status === 1) {
    //     drawUserTrack(res.data[0])
    //   }
    // })

    const homeData = await getUserHome({
      phone
    })

    if (trackData.status === 1) {
      drawUserTrack(trackData.data[0], homeData.data.geom)
    }
  }

  const drawUserTrack = (data: { inspector_list?: any[] }, geom: any) => {
    const { inspector_list = [] } = data

    const positions = inspector_list.map((item: any) => {
      const { positions } = geomToCartesian3(item.geom)
      if (item.connect_name !== inspector_list[0].connect_name) {
        const signInEntity = createBillboard(viewer, {
          position: positions[0],
          image: signInIcon,
          text: `签到时间 ${moment.unix(item.inspection_time).format('HH:mm:ss')}`
        })
        setEntities({
          key: USER_TRACK, 
          id: signInEntity.id
        })
      }
      return positions[0]
    })

    if (geom) {
      const polygonEntity = createPolygon(viewer, {
        text: `${name}（家）`,
        color: '1e90ff50',
        geom
      })
      setEntities({
        key: USER_TRACK, 
        id: polygonEntity.id
      })
    }

    if (positions.length === 0) return

    const endEntity = createBillboard(viewer, {
      position: positions[positions.length - 1],
      image: endIcon,
      text: '终点'
    })
    setEntities({
      key: USER_TRACK, 
      id: endEntity.id
    })

    if (positions.length < 2) {
      viewer.flyTo(endEntity)
      return
    }

    const startEntity = createBillboard(viewer, {
      position: positions[0],
      image: startIcon,
      text: '起点'
    })
    setEntities({
      key: USER_TRACK, 
      id: startEntity.id
    })

    const polylineEntity = createPolyline(viewer, {
      positions: positions,
      width: 5,
      color: Cesium.Color.YELLOWGREEN
    })
    setEntities({
      key: USER_TRACK, 
      id: polylineEntity.id
    })

    viewer.flyTo(polylineEntity)

  }

  return (
    <div className={styles.wrap}>
      <Spin spinning={loading}> 
        <div className={styles.modal}>
          <header>
            <span>历史记录（{name}）</span>
            <Icon type="close" onClick={hideModal} />
          </header>
          <main>
            <div className={styles.modalContent}>
              <div className={styles.calendarPanel}>
                <div className={styles.calendarHeader}>
                  <div className={styles.calendarPreBtns}>
                    <Icon type="double-left" onClick={handlePreYear} />
                    <Icon type="left" onClick={handlePreMonth} />
                  </div>
                  <div className={styles.calendarTitle}>{`${year}年${month + 1}月`}</div>
                  <div className={styles.calendarNextBtns}>
                    <Icon type="right" onClick={handleNextMonth} />
                    <Icon type="double-right" onClick={handleNextYear} />
                  </div>
                </div>
                <div className={styles.calendarBody}>
                  <table className={styles.calendarTable} cellSpacing="0" role="grid">
                    <thead>
                      <tr role="row">
                        {
                          weekTitles.map((item: WeekTitle) => (
                            <th role="columnheader" title={item.title} className={styles.calendarColumnHeader} key={item.title}>
                              <span className={styles.calendarColumnHeaderInner}>{item.inner}</span>
                            </th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody className="ant-fullcalendar-tbody">
                      {
                        arr6.map((item, index) => {
                          return (
                            <tr role="row" className="" key={index}>
                              {
                                arr7.map((subItem, subIndex) => {
                                  const num = index * 7 + subIndex
                                  const curDate = dates[num]
                                  return (
                                    <td
                                      key={num}
                                      role="gridcell"
                                      title={moment(curDate).format('YYYY年MM月DD日')}
                                      className={
                                        moment(curDate).month() < month || moment(curDate).year() < year
                                          ?
                                          styles.calendarLastMonthDay
                                          :
                                          moment(curDate).month() > month || moment(curDate).year() > year ? styles.calendaNextMonthDay : null
                                      }
                                    >
                                      <div className={styles.calendarDate}>
                                        <div
                                          onClick={() => handleSelectedDay(curDate)}
                                          className={
                                            workDay.some(val => val === moment(curDate).format('YYYY-MM-DD'))
                                              ?
                                              curDate === selectedDay
                                                ?
                                                [styles.calendarValue, styles.calendarWorkDay, styles.calendarSelectedDay].join(' ')
                                                :
                                                [styles.calendarValue, styles.calendarWorkDay].join(' ')
                                              :
                                              curDate === selectedDay
                                                ?
                                                [styles.calendarValue, styles.calendarSelectedDay].join(' ')
                                                :
                                                styles.calendarValue
                                          }
                                        >
                                          {moment(curDate).date()}
                                        </div>
                                        <div className={styles.calendarContent}></div>
                                      </div>
                                    </td>
                                  )
                                })
                              }
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <Button onClick={handleShowUserTrack} size="small" type="primary">查看轨迹</Button>
              </div>
            </div>
          </main>
        </div>
      </Spin>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
  entities: state.home.entities,
  calendarModal: state.home.calendarModal
})

const mapDispatchToProps = {
  setCalendarModal,
  setEntities
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarModal)  