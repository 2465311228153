import React, { useEffect } from 'react'
import { ConfigProvider, Input, Checkbox, Spin, Pagination, Dropdown, Menu, Icon, DatePicker, Modal, message } from 'antd'
import * as Cesium from 'cesium'
import { connect } from 'react-redux'
import styles from './style.module.styl'
import {
  setEntities,
  setMarkerEntities,
  showLabelModal,
  setMarkerListPage,
  setMarkerListPageSize,
  setMarkerListKeyword,
  setMarkerListStartTime,
  setMarkerListEndTime,
  setMarkerListParams,
  setMarkerReport,
  setMarkerCurrent,
  setMarkerCheckeds
} from '../../actions'
import {
  createBillboard,
  createLabelPolyline,
  createLabelPolygon,
  createGeomToPoint,
  createGeomToPolyline,
  createGeomToPolygon
} from '../../../utils/CesiumTool'
import { LabelAddSvg, LabelDelSvg, LabelAccountSvg } from '../../../components/Icons'
import zhCN from 'antd/es/locale/zh_CN'
import 'moment/locale/zh-cn'
import MarkerDetail from './components/marker-detail/MarkerDetail'
import pointTypes from '../../pointTypes'
import drawGraphic from '../../../utils/DrawGraphic'
import { deleteMarker } from './../../../api/home'

type LabelType = 'point' | 'line' | 'polygon'

const { Search } = Input
const { RangePicker } = DatePicker
const { confirm } = Modal

const MarkerManage = (props: any) => {
  const {
    viewer, markerEntities, setMarkerEntities,
    showLabelModal, setMarkerListPage,
    setMarkerListPageSize, setMarkerListKeyword,
    setMarkerListStartTime, setMarkerListEndTime,
    setMarkerListParams, markerList, markerListLoading,
    markerListPage, markerListPageSize,
    setMarkerReport, markerCurrent, setMarkerCurrent,
    markerCheckeds, setMarkerCheckeds
  } = props
  // const [current, setCurrent] = useState(0)
  // const [checkeds, setMarkerCheckeds] = useState<Set<number>>(new Set([]))
  // const [checkeds, setMarkerCheckeds] = useState<number[]>([])

  const { list, total } = markerList
  const currentPageCheckeds = list.map((item: any) => {
    return item.marker_id
  })

  const handelSearch = (keyword: string) => {
    setMarkerListPage(1)
    setMarkerListKeyword(keyword)
    setMarkerListParams({ page: 1, keyword })
  }

  const showMarker = (item: any) => {
    const { marker_id, type, geom, style, title, cat_id } = item
    
    if (markerEntities[marker_id]) {
      viewer.flyTo(markerEntities[marker_id])
    } else if (geom) {
      let entity
      if (type === 1) {
        entity = createGeomToPoint(viewer, geom, pointTypes[cat_id - 1] ? pointTypes[cat_id - 1].image : pointTypes[0].image, title)
      } else if (type === 2) {
        entity = createGeomToPolyline(viewer, geom, style, title)
      } else {
        entity = createGeomToPolygon(viewer, geom, style, title)
      }
      viewer.flyTo(entity)
      setMarkerEntities({
        [marker_id]: entity
      })
    }
    if (markerCheckeds.indexOf(marker_id) === -1) {
      setMarkerCheckeds([...markerCheckeds, marker_id])
    }
  }

  const handleClickItem = (item: any) => {
    setMarkerCurrent(item.marker_id)
    showMarker(item)
  }

  const onChangeChecked = (e: any, item: any, isdDel?: boolean) => {
    e.stopPropagation()
    const { marker_id } = item
    const index = markerCheckeds.indexOf(marker_id)
    let tempCheckeds: any[] = markerCheckeds.slice()

    if (index > -1) {
      tempCheckeds.splice(index, 1)
      viewer.entities.remove(markerEntities[marker_id])
      setMarkerEntities({
        [marker_id]: null
      })
    } else if (!isdDel) {
      tempCheckeds.push(item.marker_id)
      showMarker(item)
    }

    setMarkerCheckeds(tempCheckeds)
  }

  const onChangePage = (page: number) => {
    setMarkerListPage(page)
    setMarkerListParams({ page })
  }

  const onChangeTime = (date: any, dateString: any) => {
    let startTime = '', endTime = ''
    if (dateString[0] !== '' && dateString[1] !== '') {
      startTime = `${dateString[0]} 00:00:00`
      endTime = `${dateString[1]} 23:59:59`
    }
    setMarkerListStartTime(startTime)
    setMarkerListEndTime(endTime)
    setMarkerListParams({ startTime, endTime })
  }

  const addLabel = (LabelType: LabelType) => {
    // 绘制方法 point | line | polygon
    drawGraphic(viewer, LabelType, (backShapePoints: any, backShapeEntities: any) => {
      let entity, type, title, cat_id = 0

      // 删除临时实体
      backShapeEntities.forEach((entity: Cesium.Entity) => {
        viewer.entities.remove(entity)
      })

      if (LabelType === 'point') {
        type = 1
        title = '默认点标注'
        cat_id = 1
        entity = createBillboard(viewer, {
          position: backShapePoints[0],
          image: pointTypes[0].image,
          text: '默认点标注'
        })
      } else if (LabelType === 'line') {
        type = 2
        title = '默认线标注'
        entity = createLabelPolyline(viewer, {
          text: title,
          color: '1e90ff50',
          positions: backShapePoints,
        })
      } else {
        type = 3
        title = '默认面标注'
        entity = createLabelPolygon(viewer, {
          text: title,
          color: '1e90ff50',
          positions: backShapePoints
        })
      }

      showLabelModal({
        type,
        title,
        cat_id,
        detail: '',
        style: null,
        tags: [],
        show: true,
        id: null,
        entity: entity,
        positions: backShapePoints
      })
    })
  }

  const editLabel = (data: any) => {
    const { marker_id } = data

    showLabelModal({
      ...data,
      show: true,
      entity: markerEntities[marker_id],
      positions: null,
    })
  }

  const handleDeleteLabel = (e: any) => {
    e.stopPropagation()
    confirm({
      title: '确认删除选中标注？',
      onOk: () => {
        deleteMarker({
          marker_id: markerCheckeds
        }).then((res: any) => {
          if (res.status === 1) {
            message.success('删除成功')
            setMarkerListParams()
            setMarkerCheckeds([])
            markerCheckeds.forEach((item: any) => {
              viewer.entities.remove(markerEntities[item])
              setMarkerEntities({
                [item]: null
              })
            })
          } else {
            message.error('删除失败')
          }
        }).catch((err: any) => {
          message.error('删除失败')
        })
      }
    })
  }

  const handleReportLabel = (e: any) => {
    e.stopPropagation()
    setMarkerReport({
      show: true,
      checkeds: markerCheckeds
    })
  }

  const handelCheckAll = (e: any) => {
    e.stopPropagation()
    let isCheckAll = e.target.checked
    let checkeds: any[] = [].concat(markerCheckeds)

    if (!isCheckAll) {
      list.forEach((item: any) => {
        let index = checkeds.indexOf(item.marker_id)
        if (index > -1) {
          checkeds.splice(index, 1)
        }
      })
    } else {
      list.forEach((item: any) => {
        let index = checkeds.indexOf(item.marker_id)
        if (index === -1) {
          checkeds.push(item.marker_id)
        }
      })
    }

    setMarkerCheckeds(checkeds)
  }

  useEffect(() => {
    return () => {
      setMarkerListPage(1)
      setMarkerListPageSize(10)
      setMarkerListKeyword('')
      setMarkerListStartTime('')
      setMarkerListEndTime('')
    }
  }, [setMarkerListEndTime, setMarkerListKeyword, setMarkerListPage, setMarkerListPageSize, setMarkerListStartTime])

  useEffect(() => {
    setMarkerListParams({})
  }, [setMarkerListParams])

  const menu = (
    <Menu>
      <Menu.Item onClick={() => addLabel('point')}>新增点标记</Menu.Item>
      <Menu.Item onClick={() => addLabel('line')}>新增线标记</Menu.Item>
      <Menu.Item onClick={() => addLabel('polygon')}>新增面标记</Menu.Item>
    </Menu>
  )

  return (
    <section className={styles.section}>
      <header className={styles.header}>
        <span>标注管理</span>
        <div className={styles.headerBtns}>
          {/* <Button type="link" className={styles.selectAll} onClick={handelSelectAll}>{isSelectAll ? '取消全选' : '全选'}</Button> */}
          <Checkbox className={styles.checkAll} defaultChecked={false} checked={currentPageCheckeds.every((item: any) => {
            return markerCheckeds.indexOf(item) > -1
          })} onChange={handelCheckAll}>全选</Checkbox>
          <Dropdown overlay={menu} placement="bottomCenter">
            <Icon component={LabelAddSvg} className={styles.headAddIcon} />
          </Dropdown>
          <Icon component={LabelDelSvg} className={styles.headDelIcon} onClick={handleDeleteLabel} />
          <Icon component={LabelAccountSvg} className={styles.headEditIcon} onClick={handleReportLabel} />
        </div>
      </header>
      <div className={styles.rangePickerBar}>
        <ConfigProvider locale={zhCN}>
          <RangePicker size='small' onChange={onChangeTime} />
        </ConfigProvider>
      </div>
      <div className={styles.searchBar}>
        <Search onSearch={handelSearch} placeholder="搜索" />
      </div>
      <main className={styles.main}>
        <Spin spinning={markerListLoading}>
          <div className={styles.listItems}>
            {
              list.map((item: any) => {
                return (
                  <MarkerDetail
                    key={item.marker_id}
                    item={item}
                    isCurrent={markerCurrent === item.marker_id}
                    isChecked={markerCheckeds.indexOf(item.marker_id) > -1}
                    handleClickItem={handleClickItem}
                    onChangeChecked={onChangeChecked}
                    editLabel={editLabel}
                  />
                )
              })
            }
          </div>
        </Spin>
      </main>
      <footer className={styles.listItemsFooter} >
        <Pagination size="small" simple disabled={markerListLoading} total={total} pageSize={markerListPageSize} current={markerListPage} onChange={onChangePage} />
      </footer>
    </section>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
  entities: state.home.entities,
  markerEntities: state.home.markerEntities,
  markerList: state.home.markerList,
  markerListLoading: state.home.markerListLoading,
  markerListPage: state.home.markerListPage,
  markerListPageSize: state.home.markerListPageSize,
  markerCurrent: state.home.markerCurrent,
  markerCheckeds: state.home.markerCheckeds,
})

const mapDispatchToProps = {
  setEntities,
  setMarkerEntities,
  showLabelModal,
  setMarkerListPage,
  setMarkerListPageSize,
  setMarkerListKeyword,
  setMarkerListStartTime,
  setMarkerListEndTime,
  setMarkerListParams,
  setMarkerReport,
  setMarkerCurrent,
  setMarkerCheckeds
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkerManage) 