import React, {
  forwardRef,
  useImperativeHandle
} from 'react'
import Form, { FormComponentProps } from "antd/lib/form/Form"
import { Button, InputNumber } from 'antd'
import styles from './style.module.styl'

interface FormProps extends FormComponentProps {
  onSubmit: () => void
}

type Ref = FormComponentProps

const LongitudeAndLatitude = forwardRef<Ref, FormProps>(
  ({ form, onSubmit }: FormProps, ref) => {
    useImperativeHandle(ref, () => ({
      form
    }))

    const { getFieldDecorator } = form
    const formItemLayout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 16
      },
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      onSubmit()
    }

    return (
      <Form {...formItemLayout} onSubmit={handleSubmit} className={styles.paneOne}>
        <Form.Item label="经度">
          {getFieldDecorator('longitude', {
            rules: [
              {
                required: true,
                message: '输入范围：-180至180',
              },
            ],
          })(
            <InputNumber max={180} min={-180} placeholder="输入范围为：-180至180" />
          )}
          <span className="ant-form-text"> 度</span>
        </Form.Item>
        <Form.Item label="纬度">
          {getFieldDecorator('latitude', {
            rules: [
              {
                required: true,
                message: '输入范围：-90至90'
              }
            ],
          })(
            <InputNumber max={90} min={-90} placeholder="输入范围为：-90至90" />
          )}
          <span className="ant-form-text"> 度</span>
        </Form.Item>
        <Form.Item label="海拔">
          {getFieldDecorator('height', {})(
            <InputNumber min={0} />
          )}
          <span className="ant-form-text"> 米</span>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit" block>飞入</Button>
        </Form.Item>
      </Form>
    )
  }
) 

export default Form.create<FormProps>()(LongitudeAndLatitude)
