import Canvas2Image from './Canvas2Image'
import cropGif from '../static/images/crop.gif'

function mousePosition(e: any) {
  if (e.pageX || e.pageY) {
    return { x: e.pageX, y: e.pageY }
  }
  return {
    x: e.clientX + document.body.scrollLeft - document.body.clientLeft,
    y: e.clientY + document.body.scrollTop - document.body.clientTop
  }
}

// Converts canvas to an image
function convertCanvasToImage(canvas: any) {
  var image = new Image()
  image.src = canvas.toDataURL("image/png", 0.1)
  return image
}

// Converts image to canvas returns new canvas element
function convertImageToCanvas(image: any, startX: any, startY: any, width: any, height: any) {
  var canvas = document.createElement("canvas")
  canvas.width = width
  canvas.height = height
  // @ts-ignore
  canvas.getContext("2d").drawImage(image, startX, startY, width, height, 0, 0, width, height)
  return canvas
}

function screenshot(viewer: any, callback: any) {
  let onmousedownPosition: any, width = 1, height = 1, canvas, isDraw: any, onmousemovePosition, img: any, canvasDom, minX: any, minY: any
  let canvas2Image = Canvas2Image()
  let drawShade = document.createElement('div')
  let drawDom = document.createElement('div')

  let dragBox = document.createElement('div')
  let lineT = document.createElement('div')
  let lineB = document.createElement('div')
  let lineL = document.createElement('div')
  let lineR = document.createElement('div')
  lineT.setAttribute('style', `position: absolute; width: 100%; height: 1px; opacity: 0.4; background: #fff url(${cropGif});`)
  lineB.setAttribute('style', `position: absolute; bottom: 0; width: 100%; height: 1px; opacity: 0.4; background: #fff url(${cropGif});`)
  lineL.setAttribute('style', `position: absolute; left: 0; width: 1px; height: 100%; opacity: 0.4; background: #fff url(${cropGif});`)
  lineR.setAttribute('style', `position: absolute; right: 0; width: 1px; height: 100%; opacity: 0.4; background: #fff url(${cropGif});`)
  dragBox.appendChild(lineT)
  dragBox.appendChild(lineB)
  dragBox.appendChild(lineL)
  dragBox.appendChild(lineR)

  let dragDom = document.createElement('div')

  let dragN = document.createElement('div')
  let dragS = document.createElement('div')
  let dragW = document.createElement('div')
  let dragE = document.createElement('div')

  let dragT = document.createElement('div')
  let dragB = document.createElement('div')
  let dragL = document.createElement('div')
  let dragR = document.createElement('div')
  let dragLT = document.createElement('div')
  let dragLB = document.createElement('div')
  let dragRT = document.createElement('div')
  let dragRB = document.createElement('div')

  dragDom.setAttribute('style', `z-index: 310; width: 100%; height: 100%;`)
  dragBox.setAttribute('style', `position: absolute; z-index: 280; width: 100%; height: 100%; cursor: move; overflow: hidden;`)
  dragN.setAttribute('style', 'position: absolute; z-index: 300; width: 100%; height: 8px; margin-top: -4px; cursor: n-resize;')
  dragS.setAttribute('style', 'position: absolute; bottom: 0; z-index: 302; width: 100%; height: 8px; margin-bottom: -4px; cursor: s-resize;')
  dragW.setAttribute('style', 'position: absolute; left: 0; z-index: 301; width: 8px; height: 100%; margin-left: -4px; cursor: w-resize;')
  dragE.setAttribute('style', 'position: absolute; right: 0; z-index: 303; width: 8px; height: 100%; margin-right: -4px; cursor: e-resize;')

  dragT.setAttribute('style', 'position: absolute; top: 0; left: 50%; z-index: 304; width: 8px; height: 8px; margin-top: -4px; margin-left: -4px; cursor: n-resize; border: 1px #eee solid; background-color: #333; opacity: 0.5;')
  dragB.setAttribute('style', 'position: absolute; bottom: 0; left: 50%; z-index: 305; width: 8px; height: 8px; margin-bottom: -4px; margin-left: -4px; cursor: s-resize; border: 1px #eee solid; background-color: #333; opacity: 0.5;')
  dragL.setAttribute('style', 'position: absolute; left: 0; top: 50%; z-index: 306; width: 8px; height: 8px; margin-left: -4px; margin-top: -4px; cursor: w-resize; border: 1px #eee solid; background-color: #333; opacity: 0.5;')
  dragR.setAttribute('style', 'position: absolute; right: 0; top: 50%; z-index: 307; width: 8px; height: 8px; margin-right: -4px; margin-top: -4px; cursor: e-resize; border: 1px #eee solid; background-color: #333; opacity: 0.5;')

  dragLT.setAttribute('style', 'position: absolute; top: 0; left: 0; z-index: 308; width: 8px; height: 8px; margin-top: -4px; margin-left: -4px; cursor: nw-resize; border: 1px #eee solid; background-color: #333; opacity: 0.5;')
  dragLB.setAttribute('style', 'position: absolute; bottom: 0; left: 0; z-index: 309; width: 8px; height: 8px; margin-bottom: -4px; margin-left: -4px; cursor: sw-resize; border: 1px #eee solid; background-color: #333; opacity: 0.5;')
  dragRT.setAttribute('style', 'position: absolute; top: 0; right: 0; z-index: 310; width: 8px; height: 8px; margin-top: -4px; margin-right: -4px; cursor: ne-resize; border: 1px #eee solid; background-color: #333; opacity: 0.5;')
  dragRB.setAttribute('style', 'position: absolute; bottom: 0; right: 0; z-index: 311; width: 8px; height: 8px; margin-bottom: -4px; margin-right: -4px; cursor: se-resize; border: 1px #eee solid; background-color: #333; opacity: 0.5;')

  dragDom.appendChild(dragN)
  dragDom.appendChild(dragS)
  dragDom.appendChild(dragW)
  dragDom.appendChild(dragE)

  dragDom.appendChild(dragT)
  dragDom.appendChild(dragB)
  dragDom.appendChild(dragL)
  dragDom.appendChild(dragR)

  dragDom.appendChild(dragLT)
  dragDom.appendChild(dragLB)
  dragDom.appendChild(dragRT)
  dragDom.appendChild(dragRB)

  let drawTool = document.createElement('div')
  drawTool.setAttribute('style', 'display: none; position: absolute; right: 0; bottom: -40px; width: 106px; background-color: rgba(20, 24, 39, 0.9); border: 1px solid #0a4a88;')
  let drawOK = document.createElement('div')
  let drawCancel = document.createElement('div')
  drawOK.setAttribute('style', 'padding: 0 10px; height: 30px; line-height: 30px; color: #aaa; cursor: pointer;')
  drawCancel.setAttribute('style', 'padding: 0 10px; height: 30px; line-height: 30px; color: #aaa; cursor: pointer;')
  let textOK = document.createTextNode('完 成')
  let textCancel = document.createTextNode('取 消')
  drawOK.appendChild(textOK)
  drawCancel.appendChild(textCancel)
  drawTool.appendChild(drawCancel)
  drawTool.appendChild(drawOK)

  drawShade.setAttribute('style', 'position: fixed; top: 0px; left: 0px; z-index: 500; width: 100%; height: 100%; cursor: crosshair;')
  document.body.appendChild(drawShade)
  drawDom.appendChild(dragBox)
  drawDom.appendChild(dragDom)
  drawDom.appendChild(drawTool)
  // document.body.appendChild(drawDom)
  // document.body.setAttribute('style', 'cursor: crosshair')

  drawShade.onmousedown = (e) => {
    isDraw = true
    e = e || window.event
    onmousedownPosition = mousePosition(e)
    width = 2
    height = 2
    minX = Math.min(onmousedownPosition.x, onmousedownPosition.x)
    minY = Math.min(onmousedownPosition.y, onmousedownPosition.y)
    drawDom.setAttribute('style', `position: absolute; z-index: 600; top: ${minY}px; left: ${minX}px; width: ${width}px; height: ${height}px;`)
    document.body.appendChild(drawDom)
    drawTool.style.display = 'none'
  }

  document.body.onmousemove = (e) => {
    if (isDraw) {
      e = e || window.event
      onmousemovePosition = mousePosition(e)
      width = Math.abs(onmousemovePosition.x - onmousedownPosition.x)
      height = Math.abs(onmousemovePosition.y - onmousedownPosition.y)
      minX = Math.min(onmousemovePosition.x, onmousedownPosition.x)
      minY = Math.min(onmousemovePosition.y, onmousedownPosition.y)
      console.log(minX, minY)
      drawDom.setAttribute('style', `position: absolute; z-index: 600; top: ${minY}px; left: ${minX}px; width: ${width}px; height: ${height}px;`)
    }
  }

  document.body.onmouseup = (e) => {
    isDraw = false
    drawTool.style.display = 'flex'
    drawShade.onmousedown = null
    document.body.onmousemove = null
    document.body.onmouseup = null
    document.body.setAttribute('style', 'cursor: default')
    // document.body.removeChild(drawDom)
    // document.body.removeChild(drawShade)
    // drawDom.appendChild(drawTool)

    // viewer.render()
    // canvas = viewer.scene.canvas
    // img = canvas2Image.convertToImage(canvas, canvas.width, canvas.height, 'png')
    // document.body.appendChild(img)
    // img.onload = () => {
    //   img.onload = null
    //   canvasDom = convertImageToCanvas(img, minX, minY, width, height)
    //   img.src = convertCanvasToImage(canvasDom).src
    //   document.body.onmouseup = null
    //   callback(img)
    // }
  }

  drawOK.onmouseover = (e) => {
    drawOK.style.color = '#eee'
  }

  drawOK.onmouseout = (e) => {
    drawOK.style.color = '#aaa'
  }

  drawOK.onmousedown = (e) => {
    document.body.removeChild(drawDom)
    document.body.removeChild(drawShade)
    viewer.render()
    canvas = viewer.scene.canvas
    img = canvas2Image.convertToImage(canvas, canvas.width, canvas.height, 'png')
    // document.body.appendChild(img)
    img.onload = () => {
      img.onload = null
      canvasDom = convertImageToCanvas(img, minX, minY, width, height)
      img.src = convertCanvasToImage(canvasDom).src
      document.body.onmouseup = null
      callback(img)
    }
  }

  drawCancel.onmouseover = (e) => {
    drawCancel.style.color = '#eee'
  }

  drawCancel.onmouseout = (e) => {
    drawCancel.style.color = '#aaa'
  }

  drawCancel.onmousedown = (e) => {
    document.body.removeChild(drawDom)
    document.body.removeChild(drawShade)
    callback()
  }

  var disX: any, disY: any
  dragBox.onmousedown = function (event) {
    var event = event || window.event;
    disX = event.clientX - drawDom.offsetLeft;
    disY = event.clientY - drawDom.offsetTop;
    document.onmousemove = function (event) {
      var event = event || window.event;
      var iL = event.clientX - disX;
      var iT = event.clientY - disY;
      var maxL = document.documentElement.clientWidth - drawDom.offsetWidth;
      var maxT = document.documentElement.clientHeight - drawDom.offsetHeight;
      iL <= 0 && (iL = 0);
      iT <= 0 && (iT = 0);
      iL >= maxL && (iL = maxL);
      iT >= maxT && (iT = maxT);
      drawDom.style.left = iL + "px";
      drawDom.style.top = iT + "px";
      minX = iL;
      minY = iT;
      return false;
    };
    document.onmouseup = function () {
      document.onmousemove = null;
      document.onmouseup = null;
      return false;
    };
  };

  //四角点
  resize(drawDom, dragLT, true, true, false, false);
  resize(drawDom, dragRT, false, true, false, false);
  resize(drawDom, dragRB, false, false, false, false);
  resize(drawDom, dragLB, true, false, false, false);
  //四边点
  resize(drawDom, dragL, true, false, false, true);
  resize(drawDom, dragT, false, true, true, false);
  resize(drawDom, dragR, false, false, false, true);
  resize(drawDom, dragB, false, false, true, false);
  //四边bar
  resize(drawDom, dragW, true, false, false, true);
  resize(drawDom, dragN, false, true, true, false);
  resize(drawDom, dragE, false, false, false, true);
  resize(drawDom, dragS, false, false, true, false);

  /*-------------------------- +
  改变大小函数
  +-------------------------- */
  var dragMinWidth = 1;
  var dragMinHeight = 1;
  function resize(oParent: any, handle: any, isLeft: any, isTop: any, lockX: any, lockY: any) {
    handle.onmousedown = function (event: any) {
      event.preventDefault();
      var event = event || window.event;
      var disX = event.clientX - handle.offsetLeft;
      var disY = event.clientY - handle.offsetTop;
      var iParentTop = oParent.offsetTop;
      var iParentLeft = oParent.offsetLeft;
      var iParentWidth = oParent.offsetWidth;
      var iParentHeight = oParent.offsetHeight;
      document.onmousemove = function (event) {
        event.preventDefault();
        var event = event || window.event;
        var iL = event.clientX - disX;
        var iT = event.clientY - disY;
        var maxW = document.documentElement.clientWidth - oParent.offsetLeft - 2;
        var maxH = document.documentElement.clientHeight - oParent.offsetTop - 2; var iW = isLeft ? iParentWidth - iL : handle.offsetWidth + iL;
        var iH = isTop ? iParentHeight - iT : handle.offsetHeight + iT;
        isLeft && (oParent.style.left = iParentLeft + iL + "px") && (minX = iParentLeft + iL);
        isTop && (oParent.style.top = iParentTop + iT + "px") && (minY = iParentTop + iT);
        iW < dragMinWidth && (iW = dragMinWidth);
        iW > maxW && (iW = maxW);
        lockX || (oParent.style.width = iW + "px") && (width = iW);
        iH < dragMinHeight && (iH = dragMinHeight);
        iH > maxH && (iH = maxH);
        lockY || (oParent.style.height = iH + "px") && (height = iH);
        if ((isLeft && iW == dragMinWidth) || (isTop && iH == dragMinHeight)) document.onmousemove = null;
        return false;
      };
      document.onmouseup = function (event) {
        event.preventDefault();
        document.onmousemove = null;
        document.onmouseup = null;
      };
      return false;
    }
  };
}

export default screenshot