import * as React from 'react'
import { Row, Col, Button, message } from 'antd'
import { connect } from 'react-redux'
import styles from './style.module.styl'
import { setMarkerListParams, showStandingBook } from '../../../../actions'
import { deleteMarker } from './../../../../../api/home'

const MarkerDetail = React.memo((props: any) => {
  const { 
    item, isCurrent, isChecked, 
    handleClickItem, onChangeChecked, 
    editLabel, setMarkerListParams,
    showStandingBook 
  } = props

  const handleEdit = (e: any, item: object) => {
    e.stopPropagation()
    editLabel(item)
  }

  const handleDelete = (e: any, item: any) => {
    e.persist()
    deleteMarker({
      marker_id: item.marker_id
    }).then((res: any) => {
      if (res.status === 1) {
        message.success('删除成功')
        setMarkerListParams()
        onChangeChecked(e, item, true)
      } else {
        message.error('删除失败')
      }
    }).catch((err: any) => {
      message.error('删除失败')
    })
  }

  const handleStandingBook = (e: any, item: object) => {
    e.stopPropagation()
    showStandingBook({
      ...item,
      show: true
    })
  }

  return (
    <div className={isCurrent ? [styles.listItem, styles.active].join(' ') : styles.listItem} key={item.marker_id} onClick={() => { handleClickItem(item) }}>
      <div className={styles.listItemTop}>
        <img className={styles.listItemImg} src={item.img} alt='' />
        <div className={styles.listItemText}>
          <h4 className={styles.listItemTitle}>{item.title}</h4>
          <p>{item.addtime}</p>
        </div>
        <i
          className={isChecked ? [styles.checkedBox, styles.checked].join(' ') : styles.checkedBox}
          onClick={(e) => { onChangeChecked(e, item) }}
        ></i>
      </div>
      <div className={styles.listItemBottom}>
        <div>
          <label>地址：</label>
          <span>{item.address}</span>
        </div>
        <ul className={styles.markUl}>
          {
            item.tags.map((subItem: any) => {
              return (
                <li key={subItem.id}>{subItem.name}</li>
              )
            })
          }
        </ul>
      </div>
      <div className={styles.listItemFooter} style={{ display: isCurrent ? 'block' : 'none' }}>
        <Row gutter={5}>
          <Col className="gutter-row" span={8}>
            <Button type="primary" size="small" ghost onClick={(e) => { handleEdit(e, item) }}>编辑</Button>
          </Col>
          <Col className="gutter-row" span={8}>
            <Button type="primary" size="small" ghost onClick={(e) => { handleDelete(e, item) }}>删除</Button>
          </Col>
          <Col className="gutter-row" span={8}>
            <Button type="primary" size="small" ghost onClick={(e) => { handleStandingBook(e, item) }}>台账</Button>
          </Col>
        </Row>
      </div>
    </div>
  )
})

const mapStateToProps = (state: any) => ({

})

const mapDispatchToProps = {
  setMarkerListParams,
  showStandingBook
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkerDetail) 