import axios from 'axios'
// import store from '../Store'
import { message } from 'antd'

// create an axios instance
const service = axios.create({
  // @ts-ignore
  baseURL: APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // let state = { token: false }
    // state = { ...state, ...store.getState() }
    // console.log(state)
    // let token = store.getState().login.token
    let token = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken')
    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    return response.data
  },
  error => {
    console.log('err' + error) // for debug
    // message.error(error)
    return Promise.reject(error)
  }
)

export default service


export const generateCancelToken = () => {
  let cancel = null
  const cancelToken = new axios.CancelToken(c => {
    cancel = c
  })
  return {
    cancel,
    cancelToken
  }
}
