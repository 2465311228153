import request from '../utils/Request'

export function getServiceMap() {
  return request({
    url: '/api/user/get-service-map-info',
    method: 'get',
  })
}

export function updateOrigin(data: object) {
  return request({
    url: '/api/service-point/up-point',
    method: 'post',
    data
  })
}

export function getOrigin() {
  return request({
    url: '/api/service-point/get-point',
    method: 'get',
  })
}

export function getGridList() {
  return request({
    url: '/api/inspection-area/getlist',
    method: 'get',
  })
}

export function getGridInfoRequest(query: object) {
  return request({
    headers: {
      'mspace-version': 'v1.5'
    },
    url: '/api/inspector/getlist-by-area',
    method: 'get',
    params: query
  })
}

export function getUserWorkDay(query: object) {
  return request({
    url: '/api/inspector/inspection-date',
    method: 'get',
    params: query
  })
}

export function getUserTrack(query: object) {
  return request({
    url: '/api/inspector/getlist-by-phone',
    method: 'get',
    params: query
  })
}

export function getUserHome(query: object) {
  return request({
    url: '/api/inspector/home',
    method: 'get',
    params: query
  })
}

export function exportExcel(query: object) {
  return request({
    url: '/api/inspection-area/export',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}

export function getUserWarning(query: object) {
  return request({
    url: '/api/inspector/warninglist',
    method: 'get',
    params: query
  })
}

export function getUserWarningTotal(query: object) {
  return request({
    url: '/api/inspector/warningcount',
    method: 'get',
    params: query
  })
}

export function getGridWarning(query: object) { 
  return request({
    url: '/api/inspector/warningyqlist',
    method: 'get',
    params: query
  })
}

export function getMarkerList(query: object) { 
  return request({
    url: '/api/marker/get-list',
    method: 'get',
    params: query
  })
}

export function getTagList() {
  return request({
    url: '/api/tag/list',
    method: 'get'
  })
} 

export function uploadImgFile(data: object) {
  return request({
    url: '/api/upload-form/getloadfile',
    method: 'post',
    data
  })
}

// 添加标注
export function addMarker(data: object) {
  return request({
    url: '/api/marker/setmarker',
    method: 'post',
    data
  })
}

// 编辑标注
export function editMarker(data: object) {
  return request({
    url: '/api/marker/syncmarker',
    method: 'post',
    data
  })
}

// 删除标注
export function deleteMarker(data: object) {
  return request({
    url: '/api/marker/dropmarker',
    method: 'post',
    data
  })
}

export function addMarkerTag(data: object) {
  return request({
    url: '/api/marker/addtag',
    method: 'post',
    data
  })
}

export function addTag(data: object) {
  return request({
    url: '/api/tag/add',
    method: 'post',
    data
  })
}

export function getMakerTags(data: object) {
  return request({
    url: '/api/marker/gettagscount',
    method: 'post',
    data
  })
}

// 导出巡检报告word文档
export function exportMarkerReport(query: object) {
  return request({
    url: '/api/account/exportword',
    responseType: 'blob',
    method: 'get',
    params: query
  })
}

// 无人机列表
export function getUavList(query: object) { 
  return request({
    url: '/api/uav/get-uav-list',
    method: 'get',
    params: query
  })
} 

export function getUavData(query: object) { 
  return request({
    url: '/api/aircraft/new-uav-data',
    method: 'get',
    params: query
  })
} 

export function getUavVideo(query: object) { 
  return request({
    url: '/api/aircraft/video-url',
    method: 'get',
    params: query
  })
} 

export function getStandingBook(query?: object) { 
  return request({
    url: '/api/account/pagelist',
    method: 'get',
    params: query
  })
} 

export function createStandingBook(data: object) {
  return request({
    url: '/api/account/create',
    method: 'post',
    data
  })
}
 
export function updateStandingBook(data: object) {
  return request({
    url: '/api/account/update',
    method: 'post',
    data
  })
}
 
export function deleteStandingBook(query: object) {
  return request({
    url: '/api/account/delete',
    method: 'get',
    params: query
  })
}