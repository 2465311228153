import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import * as Cesium from 'cesium'
import styles from './style.module.styl'
import Header from './components/header/Header'
import UavDataStatistics from './components/uav-data-statistics/UavDataStatistics'
import UavData from '../assets/json/uav-data-statistics.json'
import signInIcon from '../static/images/visual_billboard.png'

const View = () => {
  const [viewer, setViewer] = useState<any>();
  const cesiumContainerRef = useRef<HTMLDivElement | null>(null)

  const showBillboard = (viewer: Cesium.Viewer, data: any[]) => {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const element = data[key]
        const lonlat = element[0].lonlat.split(',')
        const lon = parseFloat(lonlat[0])
        const lat = parseFloat(lonlat[1])
        const length = data[key].length.toString()
        viewer.entities.add({
          // position: Cesium.Cartesian3.fromDegrees(lon, lat, 150000.0),
          position: Cesium.Cartesian3.fromDegrees(lon, lat, 0),
          label: {
            text: length,
            font: 'normal 14px MicroSoft YaHei',
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            outlineWidth: 5,
            pixelOffset: new Cesium.Cartesian2(-20, -20),
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
          },
          billboard: {
            image: signInIcon, // default: undefined
            show: true, // default
            pixelOffset: new Cesium.Cartesian2(-20, -20), // default: (0, 0)
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
            width: 40, // default: undefined
            height: 40, // default: undefined
          },
        })
      }
    }
  }

  const consoleLog = () => {
    console.log(viewer.camera)
  }

  useEffect(() => {
    const viewer: Cesium.Viewer = new Cesium.Viewer(cesiumContainerRef.current as HTMLDivElement, {
      imageryProvider: new Cesium.UrlTemplateImageryProvider({
        url: 'http://mt1.google.cn/vt/lyrs=s&hl=zh-CN&x={x}&y={y}&z={z}&s=Gali',
      }),
      homeButton: false,            // HomeButton小部件   
      selectionIndicator: false,    // selectionIndicator小部件                 
      baseLayerPicker: false,       // 基础图层部件
      vrButton: false,              // vr部件
      animation: false,             // 时钟表
      timeline: false,              // 时钟条
      fullscreenButton: false,      // 全屏部件 
      navigationHelpButton: false,  // 导航部件
      geocoder: false,              // Geocoder小部件  
      infoBox: false,               // 是否显示点击要素之后显示的信息
    })
    setViewer(viewer)

    viewer.camera.setView({
      destination: new Cesium.Cartesian3(-1579703.6498925854, 8475818.54058179, 5513220.82386475), //相机位置
      orientation: {
        heading: 6.2831853071795845,
        pitch: -1.56858250566208,
        roll: 0
      }
    })

    let data: any[] = []
    let list = UavData as any[]
    for (var i = 0; i < list.length; i++) {
      if (!data[list[i].province]) {
        var arr = [];
        arr.push(list[i]);
        data[list[i].province] = arr;
      } else {
        data[list[i].province].push(list[i])
      }
    }

    showBillboard(viewer, data)
  }, [])

  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        <Header />
      </div>
      <div className={styles.container}>
        <div className={styles.left}>
          <UavDataStatistics data={UavData} />
        </div>
        <div className={styles.right}>
          <div className={styles.cesiumHeader} onClick={consoleLog}>无人机分布图</div>
          <div className={styles.cesiumWrap}>
            <div className={styles.cesiumContainer} ref={cesiumContainerRef}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const weatherData = state.weather

  return {
    status: weatherData.status,
  }
}

export default connect(mapStateToProps)(View)