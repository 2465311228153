import React from 'react'
import * as Cesium from 'cesium'
import { Icon, Spin, List, Pagination } from 'antd'
import styles from './style.module.styl'
import { connect } from 'react-redux'
import moment from 'moment'
import { setGridWarningModal, setEntities } from '../../actions'
import { getGridWarning, getUserTrack, getUserHome } from '../../../api/home' 
import { 
  geomToCartesian3, 
  createPolygon,
  createBillboard,
  createPolyline
} from '../../../utils/CesiumTool'
import signInIcon from '../../../static/icons/alphabet_C.png'
import startIcon from '../../../static/icons/alphabet_S.png'
import endIcon from '../../../static/icons/alphabet_E.png'
import { USER_TRACK } from '../../entitiesKey'

const { useState, useEffect } = React

const GridWarningModal = (props: any) => {
  const { viewer, entities, setEntities } = props
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState<any[]>([])

  const hideModal = () => {
    props.setGridWarningModal({
      show: false
    })
  }

  // useEffect(() => {
  //   getGridWarningTotal({
  //     area_id: gridInfo.id
  //   }).then(res => {
  //     if (res.status === 1) {
  //       setTotal(Number(res.data.total))
  //     }
  //   })
  // }, [gridInfo.id])

  useEffect(() => {
    setLoading(true)
    getGridWarning({ 
      page_size: pageSize,
      page: page
    }).then(res => {
      if (res.status === 1) {
        setList(res.data.list)
        setTotal(Number(res.data.total))
        setLoading(false)
      }
    }).finally(() => {
      setLoading(false)
    })
  }, [page, pageSize])

  const onChangePage = (page: number) => {
    setPage(page)
  }

  const clearEntities = (key: string) => {
    if (entities[key]) {
      entities[key].forEach((element: string) => {
        viewer.entities.removeById(element)
      })
    }
  }

  const handleShowGridTrack = async (item: { phone: string, day: string }) => {
    clearEntities(USER_TRACK)
    const { phone, day } = item
    const trackData = await getUserTrack({
      phones: phone,
      date: `${day.substring(0, 4)}-${day.substring(4, 6)}-${day.substring(6, 8)}`
    })

    const homeData = await getUserHome({
      phone
    })

    if (trackData.status === 1) {
      drawGridTrack(trackData.data[0], homeData.data.geom)
    }
  }

  const drawGridTrack = (data: { inspector_list?: any[], name: string }, geom: any) => {
    const { inspector_list = [], name } = data

    const positions = inspector_list.map((item: any) => {
      const { positions } = geomToCartesian3(item.geom)
      if (item.connect_name !== inspector_list[0].connect_name) {
        const signInEntity = createBillboard(viewer, {
          position: positions[0],
          image: signInIcon,
          text: `签到时间 ${moment.unix(item.inspection_time).format('HH:mm:ss')}`
        })
        setEntities({
          key: USER_TRACK, 
          id: signInEntity.id
        })
      }
      return positions[0]
    })

    if (geom) {
      const polygonEntity = createPolygon(viewer, {
        text: `${name}（家）`,
        color: '1e90ff50',
        geom
      })
      setEntities({
        key: USER_TRACK, 
        id: polygonEntity.id
      })
    }

    if (positions.length === 0) return

    const endEntity = createBillboard(viewer, {
      position: positions[positions.length - 1],
      image: endIcon,
      text: '终点'
    })
    setEntities({
      key: USER_TRACK, 
      id: endEntity.id
    })

    if (positions.length < 2) {
      viewer.flyTo(endEntity)
      return
    }

    const startEntity = createBillboard(viewer, {
      position: positions[0],
      image: startIcon,
      text: '起点'
    })
    setEntities({
      key: USER_TRACK, 
      id: startEntity.id
    })

    const polylineEntity = createPolyline(viewer, {
      positions: positions,
      width: 5,
      color: Cesium.Color.YELLOWGREEN
    })
    setEntities({
      key: USER_TRACK, 
      id: polylineEntity.id
    })

    viewer.flyTo(polylineEntity)

  }

  return (
    <div className={styles.wrap}>
      <Spin spinning={loading}>
        <div className={styles.modal}>
          <header>
            <span>重点区域疫情警告</span>
            <Icon type="close" onClick={hideModal} />
          </header>
          <div className={styles.wraningBar}>
            <span className={styles.text}>共记警告信息{total}条</span>
          </div>
          <main>
            <div className={styles.modalContent}>
              <List
                className="demo-loadmore-list"
                loading={false}
                itemLayout="horizontal"
                dataSource={list}
                renderItem={item => (
                  <List.Item
                    style={{ paddingLeft: '10px' }}
                    actions={[<a key="list-loadmore-more" onClick={() => handleShowGridTrack(item)}>查看</a>]}
                  >
                    <div>{item.content}</div>
                  </List.Item>
                )}
              />
            </div>
          </main>
          <footer>
            <Pagination size="small" simple total={total} pageSize={pageSize} current={page} onChange={onChangePage} />
          </footer>
        </div>
      </Spin>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
  entities: state.home.entities,
})

const mapDispatchToProps = {
  setEntities,
  setGridWarningModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(GridWarningModal)  